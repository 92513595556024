// Libraries
import { useEffect, useState } from 'react';
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from '@microsoft/signalr';

// MUI
// Core
import log from '../../core/log';

// Common
// Interfaces
// Store
// API

interface IUseSignalRConnection {
    hubUrl: string;
}

const useSignalRConnection = ({ hubUrl }: IUseSignalRConnection) => {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {
        const createConnection = () => {
            return new HubConnectionBuilder()
                .withUrl('/notificationhub', {
                    transport: HttpTransportType.WebSockets,
                    skipNegotiation: true,
                    withCredentials: true,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();
        };

        const hubConnection = createConnection();
        setConnection(hubConnection);

        const startConnection = async () => {
            try {
                await hubConnection.start();
            } catch (err) {
                log('error', `SignalR connection error: ${err}`);
            }
        };

        startConnection();

        // Cleanup connection on unmount
        return () => {
            if (hubConnection) {
                hubConnection.stop();
            }
        };
    }, [hubUrl]);

    return connection;
};

export default useSignalRConnection;
