// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { GridCellParams } from '@mui/x-data-grid-pro';

// Core
import FormValidator from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { ADataGrid } from '../../../common/dataGrid';
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../../common/dialog';
import { ANumberInput } from '../../../common/inputs';

// Interfaces
// Store
// API
// Feature
import CloseDialogButton from '../../announcements/components/general/CloseDialogButton';
import { IProductionPlanDataGrid } from './ProductionPlanDataGrids';
import '../../shared/styles/sharedStyles.scss';
import { enqueueSnackbar } from 'notistack';
import SectionTitle from './SectionTitle';
import { Grid } from '@mui/material';
import AColorizedDialogSubHeader from '../../../common/dialog/ADialogColorizedSubHeader';

interface IProps {
    title: string;
    data: IProductionPlanDataGrid;
    onSave: (newData: IProductionPlanDataGrid) => void;
    onClose: () => void;
}

export default function EditProductionAnimalDataDialog(props: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCattleProductionPlan',
    ]);
    const [validator] = useState<FormValidator>(new FormValidator());
    const [data, setData] = useState<IProductionPlanDataGrid>();

    const loadData = () => {
        setData(JSON.parse(JSON.stringify(props.data)));
    };

    const reset = () => {
        // Due to some race-conditions, with reset button and input updates, when directly clicking the reset from a focused input;
        // Set data to undefined, forcing a re-render that then re-loads the data with the useEffect, making sure all input-changes
        // are processed before re-setting passed values.
        setData(undefined);
    };

    const renderCell = (params: GridCellParams) => {
        return params.value?.toString() ?? '';
    };

    /**
     * Data from Tiltu shouldn't be modifiable. Rules for the non-modifiable rows at the moment are:
     * - when row title is AnelmaCattleProductionPlan:1007
     *
     * Additional information from the JIRA ticket: http://jira.mtech.fi/browse/AN-1782
     *
     * @param params Row data
     */
    const renderEditCell = (params: GridCellParams) => {
        const row = data?.rows.find((x) => x.id === params.row.id) || {};
        return (
            <ANumberInput
                id={`edit-cell-${params.row.id}-${params.field}`}
                label={''}
                validator={validator}
                value={params.value?.toString() ? parseInt(params.value?.toString()) : null}
                onChange={(v) => {
                    row[params.field] = v;
                }}
                mode={'int'}
                withoutContainer
                disabled={row && row.title === t('AnelmaCattleProductionPlan:1007') ? true : false}
                min={0}
                required={true}
            />
        );
    };

    useEffect(() => {
        loadData();
    }, [props.data]);

    useEffect(() => {
        if (!data) loadData();
    }, [data]);

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            <ADialogTitle>
                {props.title}
                <CloseDialogButton
                    className='animal-announcement-close-button-float-right'
                    onClick={props.onClose}
                />
            </ADialogTitle>
            <AColorizedDialogSubHeader>{t('AnelmaLayout:1085')}</AColorizedDialogSubHeader>

            <ADialogContent size={'lg'}>
                {data && (
                    <ADataGrid
                        disableSelectionOnClick={true}
                        columns={data.columns.map((x, i) => {
                            x.headerName = t(x.headerName || '');
                            return {
                                sortable: false,
                                renderCell: i === 0 ? renderCell : renderEditCell,
                                ...x,
                            };
                        })}
                        rows={data.rows.map((x) => {
                            x.title = t(x.title);
                            return x;
                        })}
                    />
                )}
            </ADialogContent>
            <ADialogActions
                buttons={
                    data
                        ? {
                              left: [
                                  {
                                      onClick: () => reset(),
                                      label: t('AnelmaGeneral:1003'),
                                      type: 'cancel',
                                  },
                              ],
                              right: [
                                  {
                                      onClick: () => {
                                          if (validator.invalid) {
                                              enqueueSnackbar(
                                                  t('AnelmaCattleProductionPlan:1084'),
                                                  { variant: 'error' }
                                              );
                                          } else {
                                              props.onSave(data);
                                          }
                                      },
                                      label: t('AnelmaGeneral:1054'),
                                      type: 'ok',
                                  },
                                  {
                                      onClick: () => {
                                          if (validator.invalid) {
                                              enqueueSnackbar(
                                                  t('AnelmaCattleProductionPlan:1084'),
                                                  { variant: 'error' }
                                              );
                                          } else {
                                              props.onSave(data);
                                              props.onClose();
                                          }
                                      },
                                      label: `${t('AnelmaGeneral:1054')} + ${t(
                                          'AnelmaGeneral:1152'
                                      )}`,
                                      type: 'ok',
                                  },
                              ],
                          }
                        : { left: [], right: [] }
                }
            />
        </ADialog>
    );
}
