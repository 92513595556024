// Libraries
import { clone } from 'ramda';

// Core
import auth from '../../../../core/authorization';

// Feature
import {
    MainMenuItemType,
    IMainMenuItem,
    IMainMenuLink,
    ISubMenuItemLink,
    ISubMenuItemPublic,
} from '../mainMenu/IMainMenu';
import { publicMenuItems } from './public';
import { admin } from './admin';
import { adviser } from './adviser';
import { animalInfo } from './animalInfo';
import { economy } from './economy';
import { reporting } from './reporting';
import { responsibility } from './responsibility';

const internalMenuItems: MainMenuItemType[] = [animalInfo, economy, reporting, adviser, admin, responsibility];

export const getMenuItems = (loggedIn: boolean) => {
    const menuItems = loggedIn ? internalMenuItems : publicMenuItems;
    const filteredMenuItems: MainMenuItemType[] = [];

    menuItems.forEach((main) => {
        const link = clone(main) as IMainMenuLink;

        if (link.href) {
            filteredMenuItems.push(link);
            return;
        }

        const origItem = main as IMainMenuItem;
        const item = clone(main) as IMainMenuItem;

        item.itemGroups = [];

        origItem.itemGroups.forEach((group) => {
            const group2 = clone(group);
            group2.items = [];

            group.items.forEach((sub) => {
                const pub = sub as ISubMenuItemPublic;
                const sub2 = sub as ISubMenuItemLink;
                if (pub.public || auth.hasAccess(sub2.resource, sub2.scope)) group2.items.push(sub);
            });

            if (group2.items.length) item.itemGroups.push(group2);
        });

        // Show all defined links for public site
        if (!loggedIn || item.itemGroups.length) filteredMenuItems.push(item);
    });

    return filteredMenuItems;
};
