// Libraries
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Container } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Core
import auth from '../../core/authorization';

// Common
import useMediaQuery from '../../common/utils/useMediaQuery';

// Store
import { useAppSelector } from '../../store/hooks';

// Feature
import './header.scss';
import HeaderDivider from './header/headerDivider/HeaderDivider';
import HeaderLogo from './header/headerLogo/HeaderLogo';
import BulletinBar from './header/bulletinBar/BulletinBar';
import CultureSelector from './header/cultureSelector/CultureSelector';
import LoginLink from './header/loginLink/LoginLink';
import MainMenu from './header/mainMenu/MainMenu';
import MobileMenu from './header/mobile/MobileMenu';
import MobileMenuOpen from './header/mobile/MobileMenuOpen';
import SubMenuContainer from './header/subMenu/SubMenuContainer';
import UserMenu from './header/userMenu/UserMenu';
import { IMainMenuItem } from './header/mainMenu/IMainMenu';
import { getMenuItems } from './header/menuItems/menuItems';
import NotificationView from '../../notifications/NotificationView';
import { RoutePaths } from '../../interfaces/enums';

export default function Header(props: { appFullyInitialized: boolean }) {
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const loggedIn = loginStatus.data.loggedIn;
    const menuItems = getMenuItems(!!loggedIn);
    const mobileHeader = useMediaQuery(1200, 'down');
    const tinyHeader = useMediaQuery(460, 'down');

    const [openMenuItem, setOpenMenuItem] = useState<number | null>(null);
    const [touchMenu, setTouchMenu] = useState<boolean>(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const headerClassName = (loggedIn: boolean, mobileHeader: boolean) => {
        const classes: string[] = [];
        if (mobileHeader) classes.push('an-header-mobile');
        classes.push(loggedIn ? 'an-header-app' : 'an-header-public');
        return classes.join(' ');
    };

    useEffect(() => {
        setMobileMenuOpen(false);
    }, [mobileHeader]);

    const closeSubMenu = () => {
        setOpenMenuItem(null);
        setTouchMenu(false);
    };

    const canDisplayNotificationView = () => {
        if (!mobileHeader && loggedIn && auth.canViewNotificationsList && props.appFullyInitialized)
            return true;
        return false;
    };

    const handleIconClick = () => {
        navigate(RoutePaths.GeneralCalendar);
    };

    return loggedIn === null ? null : (
        <div
            className={`an-header ${headerClassName(!!loggedIn, mobileHeader)}`}
            onMouseLeave={() => setOpenMenuItem(null)}
        >
            {mobileHeader && <BulletinBar />}

            <div className='an-header-top'>
                <Container className='an-header-top-container'>
                    <HeaderLogo />

                    <div style={{ marginLeft: 'auto' }}></div>

                    {!mobileHeader && (
                        <MainMenu
                            items={menuItems}
                            openMenuItem={openMenuItem}
                            openSubMenu={(index, touch) => {
                                setOpenMenuItem(index);
                                setTouchMenu(touch);
                            }}
                        />
                    )}

                    {!loggedIn && !mobileHeader && (
                        <>
                            <CultureSelector />

                            <HeaderDivider />
                        </>
                    )}

                    {loggedIn ? (
                        <UserMenu
                            closeSubMenu={closeSubMenu}
                            mobile={mobileHeader}
                            open={userMenuOpen}
                            setMenuOpen={(open: boolean) => setUserMenuOpen(open)}
                        />
                    ) : (
                        <LoginLink tinyHeader={tinyHeader} />
                    )}

                    {mobileHeader && (
                        <>
                            {!loggedIn && <HeaderDivider />}

                            <MobileMenuOpen onOpen={() => setMobileMenuOpen(true)} />

                            <MobileMenu
                                items={menuItems}
                                onClose={() => setMobileMenuOpen(false)}
                                open={mobileMenuOpen}
                            />
                        </>
                    )}
                    {!mobileHeader && loggedIn && auth.canViewCalendar && (
                        <CalendarMonthIcon
                            style={{ color: '#fff', marginLeft: '20px', cursor: 'pointer' }}
                            onClick={handleIconClick}
                        />
                    )}

                    {canDisplayNotificationView() && <NotificationView />}
                </Container>
            </div>

            {!mobileHeader && (
                <SubMenuContainer
                    closeSubMenu={closeSubMenu}
                    menuItems={menuItems.map((i) => {
                        const item = i as IMainMenuItem;
                        return item;
                    })}
                    openMenuItem={openMenuItem}
                    touchMenu={touchMenu}
                />
            )}

            {!mobileHeader && props.appFullyInitialized && <BulletinBar />}
        </div>
    );
}
