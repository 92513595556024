// Libraries
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { isNotNil } from 'ramda';

// MUI
import { Tooltip } from '@mui/material';

// Core
import auth from '../../core/authorization';
import log from '../../core/log';

// Common
// Interfaces
import { INotificationResult, NotificationTypeEnum } from '../../interfaces/INotification';
import { RoutePaths } from '../../interfaces/enums';
import { ICompanyFarmId } from '../../interfaces/IBusinessEntities';
import { IProductionPlanNotificationLocationData } from '../../interfaces/IProductionPlan';
import { IConversationNotificationData } from '../../interfaces/ICommunication';
import { IFeedbackNotificationData } from '../../interfaces/communication/feedback/IFeedbackNotificationData';

// Store
import { useAppSelector } from '../../store/hooks';

// API
// Feature - Production plan
import { canCreate } from '../../animals/production-plan/helpers/ProductionPlanAccessRights';

// Feature - Farm visits
import { IFarmVisitNotificationLocationData } from '../../farmVisit/IFarmVisitNotificationLocationData';

// Feature - Notifications
import { NotificationViewContext } from '../context/INotificationContext';

const StyledContentDiv = styled('div')(({ theme }) => ({
    height: '34px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
}));

interface INotificationContentProps {
    notification: INotificationResult;
}

/** Display notification's content. When content is clicked do action according to the content's type
 *
 * @props notification: INotification
 */
export default function NotificationContent({ notification }: INotificationContentProps) {
    const { deleteNotification, restoreAnchor } = useContext(NotificationViewContext);
    const configuration = useAppSelector((state) => state.configuration);

    const navigate = useNavigate();
    const currentLocation = useLocation();

    /** I see no point in adding to the Anelma resources. */
    const MISSING_IMPLEMENTATION_MSG: string = `#Missing implementation for type ${notification.TypeId}`;

    /** Handle specific cases of resource text. Sometimes there are none.
     *
     * @returns
     */
    const getContent = () => {
        const culture = configuration.data.culture.supportedCultures.find((i) => i.selected)?.value;

        if (isNotNil(culture)) {
            return notification.ResourceTexts.find((_) => _.Culture === culture)?.Text;
        }

        return '';
    };

    const routeToNotificationOrigin = () => {
        switch (notification.TypeId) {
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
                handleRoutingToOrigin(
                    RoutePaths.FarmVisitDialogEdit,
                    RoutePaths.FarmVisitDialogEdit,
                    {
                        FarmVisitId: notification.Key.NumbericKey,
                        FarmId: notification.FarmId,
                    } as IFarmVisitNotificationLocationData,
                    auth.canViewFarmVisitsProducer
                );
                break;
            case NotificationTypeEnum.NewInvoice:
            case NotificationTypeEnum.NewCreditReport:
            case NotificationTypeEnum.NewCreditNote:
                handleRoutingToOrigin(
                    RoutePaths.EconomyInvoices,
                    RoutePaths.InvoiceDetailView,
                    {
                        invoiceNumber: notification.Key.NumbericKey,
                    },
                    auth.canViewEconomyInvoice
                );
                break;
            case NotificationTypeEnum.NewCompany:
                handleRoutingToOrigin(
                    RoutePaths.GeneralFarmInfo,
                    RoutePaths.GeneralFarmInfo,
                    {
                        Id: notification.Key.UuidKey,
                        FarmId: notification.Key.UuidKey,
                    } as ICompanyFarmId,
                    auth.canUpdateCompany
                );
                break;

            case NotificationTypeEnum.CreateProductionPlanForNextYear:
                handleRoutingToOrigin(
                    RoutePaths.AnimalsProductionPlans,
                    RoutePaths.ProductionPlanDialogRoot,
                    {
                        DisplayProductionPlanFormInstantly: true,
                    } as IProductionPlanNotificationLocationData,
                    canCreate()
                );
                break;
            case NotificationTypeEnum.NewMessageInNewConversation:
            case NotificationTypeEnum.NewMessageInOldConversation:
                handleRoutingToOrigin(
                    RoutePaths.GeneralMessaging,
                    RoutePaths.ConversationDialogEdit,
                    {
                        ConversationId: notification.Key.UuidKey,
                    } as IConversationNotificationData,
                    auth.canViewMessages
                );
                break;
            case NotificationTypeEnum.NewFeedbackMessageInOldFeedbackItem:
            case NotificationTypeEnum.FeedbackStatusChanged:
            case NotificationTypeEnum.NewFeedback:
                handleRoutingToOrigin(
                    RoutePaths.GeneralFeedback,
                    RoutePaths.FeedbackEdit,
                    {
                        FeedbackId: notification.Key.NumbericKey,
                    } as IFeedbackNotificationData,
                    auth.canViewFeedback
                );
                break;
            default:
                alert(MISSING_IMPLEMENTATION_MSG);
                log('error', MISSING_IMPLEMENTATION_MSG);
                break;
        }
    };

    /** Call routeTo with location that user is routed to
     *
     * When current location is the same as primaryTargetLocation there is no need to check if the user has access to the resource. That's been checked elsewhere already.
     * @param primaryTargetLocation RoutePaths. Path where the user is primarily routed to. Keep in mind that the user can already be located at the primary loc. If so, there has to be an alternative loc. to render the wanted result.
     * @param altTargetLocation RoutePaths. Path where the user is alternatively routed to.
     * @param options any. Options for useNavigate hook. NOTE! If you find a way to type this easily then please do so.
     * @param hasAccess boolean. Checked when called if an user has access to the resource in given as a parameter.
     */
    const handleRoutingToOrigin = (
        primaryTargetLocation: RoutePaths,
        altTargetLocation: RoutePaths,
        options: any,
        hasAccess: boolean
    ) => {
        if (currentLocation.pathname === primaryTargetLocation) routeTo(altTargetLocation, options);
        else {
            if (hasAccess) routeTo(primaryTargetLocation, options);
        }
    };

    const routeTo = (path: RoutePaths, options: any) => {
        navigate(path, {
            state: {
                options,
            },
        });

        // Check out the design doc. for further information
        if (
            notification.TypeId === NotificationTypeEnum.NewMessageInNewConversation ||
            notification.TypeId === NotificationTypeEnum.NewMessageInOldConversation
        )
            deleteNotification(notification);

        restoreAnchor();
    };

    return (
        <Tooltip title={getContent()} placement='top'>
            <StyledContentDiv onClick={() => routeToNotificationOrigin()}>
                {getContent()}
            </StyledContentDiv>
        </Tooltip>
    );
}
