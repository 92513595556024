// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { isNotNil } from 'ramda';
import moment from 'moment';
import { HubConnection } from '@microsoft/signalr';

// MUI
import { Badge, styled } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

// Core
import { ResourceTextApplication } from '../core/resources';

// Common
import { Context } from '../common/contextSelector/ContextSelector';
import useSignalRConnection from '../common/utils/useSignalRConnection';

// Interfaces
import {
    IModifyNotifications,
    IModifyNotificationsPayload,
    INotificationResult,
    NotificationFlagsEnum,
    NotificationTypeEnum,
} from '../interfaces/INotification';
import { IFarmDetails } from '../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../store/hooks';

// API
import companyApi from '../api/companyApi';
import notificationApi from '../api/notificationApi';

// Feature - Notifications
import NotificationList from './NotificationList';
import { NotificationViewContext } from './context/INotificationContext';
import { getNotificationHubUrl } from './hub/NotificationHubHelper';

const StyledNotificationIconWrapper = styled('div')(({ theme }) => ({
    svg: {
        fill: '#fff',
        marginLeft: '1em',
        transform: 'scale(1.25)',
    },
}));

/** Mother of all notification components. Requirements for viewing are set from the component that this is rendered from.
 */
export default function NotificationView() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaNotifications',
    ]);
    const notificationHubConnection = useSignalRConnection({
        hubUrl: getNotificationHubUrl(),
    });

    const userData = useAppSelector((state) => state.userData.data);
    const context = useAppSelector((state) => state.context);

    const [anchor, setAnchor] = useState<Element | null>(null);
    const [notifications, setNotifications] = useState<INotificationResult[]>([]);
    const [receivedNotification, setReceivedNotification] = useState<INotificationResult>();
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();

    useEffect(() => {
        initializeReceivedNotificationState();
        if (isNotNil(notificationHubConnection)) receiveNotification(notificationHubConnection);
    }, [notificationHubConnection]);

    useEffect(() => {
        if (context.data.currentContext) {
            setFarmDetails(undefined);
            setNotifications([]);
            initializeFarmDetails(context.data.currentContext);
        }
    }, [context]);

    useEffect(() => {
        if (notifications.length === 0) getNotifications();
    }, [farmDetails]);

    useEffect(() => {
        if (isNotNil(receivedNotification)) updateNotificationState(receivedNotification);
    }, [receivedNotification]);

    const initializeFarmDetails = (context: Context) => {
        companyApi
            .getCompanyByContext(context.context)
            .then((response) => setFarmDetails(response?.Entity as IFarmDetails));
    };

    const receiveNotification = (hubConnection: HubConnection) => {
        hubConnection.on('ReceiveNotification', (message) =>
            setReceivedNotification(JSON.parse(message) as INotificationResult)
        );
    };

    const updateNotificationState = async (data: INotificationResult) => {
        if (allowedToUpdateNotificationState(data)) {
            setNotifications((previousState) => {
                if (data.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear) {
                    return [data, ...previousState];
                }
                return [...previousState, data];
            });
        }
        initializeReceivedNotificationState();
    };

    const getNotifications = () => {
        if (userData.Id && isNotNil(farmDetails?.Id)) {
            notificationApi
                .getNotifications(userData.Id, farmDetails.Id, userData.IsSnellmanUser ?? false)
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaNotifications:1015'), {
                            variant: 'error',
                        });
                        return;
                    }
                    setNotifications(_.Items);
                });
        }
    };

    const getUnreadNotifications = () => {
        return notifications.filter((_) => _.ReadDateTime === null).length;
    };

    const setRead = (data: INotificationResult[]) => {
        notificationApi.modifyNotifications(mapPayload(data, 'setRead', userData.Id)).then((_) => {
            if (_ === null) {
                enqueueSnackbar(t('AnelmaNotifications:1016'), {
                    variant: 'error',
                });
                return;
            }

            setNotifications(getModifiedNotificationsWhenSetRead([...notifications], _.Items));
        });
    };

    const mapPayload = (
        data: INotificationResult[],
        operation: 'setRead' | 'setDeleted',
        userId: string | null
    ): IModifyNotifications => {
        const payload: IModifyNotifications = { Items: [] };
        const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
        const currentDateTime = moment().utc().format(dateTimeFormat);

        data.forEach((notification) => {
            const row: IModifyNotificationsPayload = {
                UserId: userId || '',
                NotificationId: notification.NotificationId,
                Flags:
                    operation === 'setRead'
                        ? NotificationFlagsEnum.Read
                        : NotificationFlagsEnum.Deleted,
                ReadDateTime: operation === 'setRead' ? currentDateTime : null,
                DeletedDateTime: operation === 'setDeleted' ? currentDateTime : null,
            };
            payload.Items.push(row);
        });

        return payload;
    };

    const getModifiedNotificationsWhenSetRead = (
        notifications: INotificationResult[],
        updatedNotifications: INotificationResult[]
    ): INotificationResult[] => {
        return notifications.map((n) => {
            const updatedNotification = updatedNotifications.find(
                (u) => u.NotificationId === n.NotificationId
            );

            // This mapping saves a lot of backend work as the basic properties are not changing just only the marked ones
            return updatedNotification
                ? {
                      NotificationId: n.NotificationId,
                      TypeId: n.TypeId,
                      Flags: updatedNotification.Flags, // Updated
                      Created: n.Created,
                      ReadDateTime: updatedNotification.ReadDateTime, // Updated
                      DeletedDateTime: updatedNotification.DeletedDateTime, // Updated
                      ResourceTexts: n.ResourceTexts,
                      Key: n.Key,
                      FarmId: n.FarmId,
                  }
                : n;
        });
    };

    /** Delete single notification. In case production plan notification and the notification currently in state, display error message.
     *
     * @param data INotification
     */
    const deleteNotification = (data: INotificationResult) => {
        if (
            data.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear &&
            notifications.find((_) => _.NotificationId === data.NotificationId)
        ) {
            enqueueSnackbar(t('AnelmaNotifications:1020'), {
                variant: 'error',
            });
        } else {
            notificationApi
                .modifyNotifications(mapPayload([data], 'setDeleted', userData.Id))
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaGeneral:1145'), {
                            variant: 'error',
                        });
                        return;
                    }
                    setNotifications([
                        ...notifications.filter((_) => _.NotificationId !== data.NotificationId),
                    ]);
                });
        }
    };

    /** Delete all notifications that are in the state. Is used only when user clicks AnelmaNotifications:1000 NotificationList component. */
    const deleteAllNotifications = () => setNotificationsAsDeleted();

    const setNotificationsAsDeleted = (data?: INotificationResult[]) => {
        const notificationsToPurge =
            data !== undefined && data.length > 0
                ? data
                : notifications.filter(
                      (_) => _.TypeId !== NotificationTypeEnum.CreateProductionPlanForNextYear
                  );

        if (notificationsToPurge.length > 0)
            notificationApi
                .modifyNotifications(mapPayload(notificationsToPurge, 'setDeleted', userData.Id))
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaGeneral:1145'), {
                            variant: 'error',
                        });
                        return;
                    }
                    setNotifications(
                        notifications.filter((n) => !notificationsToPurge.includes(n))
                    );
                });
        else displayNothingToDeleteWarning();
    };

    const displayNothingToDeleteWarning = () => {
        enqueueSnackbar(t('AnelmaNotifications:1021'), {
            variant: 'warning',
        });
    };

    const initializeReceivedNotificationState = () => {
        setReceivedNotification(undefined);
    };

    /** Last guard rail to stop notification from being displayed. Snellman user can be part of company, but just doesn't need to see some kind of notifications and vice versa (for producer user).
     */
    const allowedToUpdateNotificationState = (notification: INotificationResult) => {
        // 1st condition: In reality user is still part of feedback even though the user 'level' is producer or other... Just yet another mind numbing customer approved feature.
        // 2nd, 3rd and 4th condition: Snellman users in the end will have superuser access rights so they can access the objects no matter what.
        const snellmanUser = isNotNil(userData.IsSnellmanUser);

        if (!snellmanUser && notification.TypeId === NotificationTypeEnum.NewFeedback) {
            return false;
        } else if (
            snellmanUser &&
            (notification.TypeId === NotificationTypeEnum.NewInvoice ||
                notification.TypeId === NotificationTypeEnum.NewCreditNote ||
                notification.TypeId === NotificationTypeEnum.NewCreditReport)
        ) {
            return false;
        } else if (
            snellmanUser &&
            notification.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm
        ) {
            return false;
        } else if (
            snellmanUser &&
            notification.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear
        ) {
            return false;
        }

        return true;
    };

    return (
        <>
            <StyledNotificationIconWrapper onClick={(event) => setAnchor(event.currentTarget)}>
                <Badge
                    badgeContent={getUnreadNotifications()}
                    color='error'
                    showZero
                    sx={{ cursor: 'pointer' }}
                >
                    <NotificationsNoneIcon />
                </Badge>
            </StyledNotificationIconWrapper>

            {anchor && (
                <NotificationViewContext.Provider
                    value={{
                        notifications: notifications,
                        setNotifications: (_: INotificationResult[]) =>
                            setNotifications((previousState) => [...previousState, ..._]),
                        setRead: (_: INotificationResult[]) => setRead(_),
                        deleteNotification: (_: INotificationResult) => deleteNotification(_),
                        deleteNotifications: () => deleteAllNotifications(),
                        restoreAnchor: () => setAnchor(null),
                    }}
                >
                    <NotificationList anchor={anchor} />
                </NotificationViewContext.Provider>
            )}
        </>
    );
}
