// Libraries
import moment from 'moment';

// MUI
// Core
// Common
// Interfaces
import { IModifyNotifications, INotificationResult } from '../interfaces/INotification';

// Store
// API
import { notificationsUrl } from './apiEndpoints';
import ApiBase from './ApiBase';

// Feature - Notifications

class NotificationApi extends ApiBase {
    getNotifications = (userId: string, farmId: string, snellmanUser: boolean) =>
        this.getList<INotificationResult>(
            `${notificationsUrl}/from/${moment()
                .subtract(30, 'days')
                .format(
                    'YYYY-MM-DDTHH:mm:ss'
                )}/userId/${userId}/farmId/${farmId}/snellmanUser/${snellmanUser}`
        );

    modifyNotifications = (payload: IModifyNotifications) =>
        this.postList<INotificationResult>(`${notificationsUrl}/Modify`, payload);
}

export default new NotificationApi();
