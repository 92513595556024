// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    IconButton,
    Paper,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';

// Core
import FormValidator from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { AAddButton } from '../../../common/buttons';
import { ADropdown, ADropdownItemProps, ASwitch } from '../../../common/inputs';

// Interfaces
import {
    IPredefinedSearch,
    ISearchParameter,
    SearchGroup,
    SearchType,
} from '../../../interfaces/ISearchParameters';

// Feature
import { IParameterExtension } from '../../shared/styles/types/subTypes';
import {
    GetAnnouncementWeekValue,
    GetValueToSave,
    GetValueToShow,
    SearchTypeFromIntToString,
} from '../../Helpers/Parsers';
import { getTypeFromCode, notAnAnnouncementWeekValue } from '../../Helpers/general';
import Parameter, { isMultiselect, isRadiobuton } from './Parameter';

export interface IPredefinedSearchAccordionProps {
    title: string;
    placeholder: string;
    searchChanged: (data: IPredefinedSearch) => any;
    data: IPredefinedSearch;
    parameters: IParameterExtension[];
    personParameters: IParameterExtension[];
    announcementParameters: IParameterExtension[];
    searchGroup: SearchGroup;
    usePersonsConnectedToTheFarm: boolean;
    setUsePersonsConnectedToTheFarm: (state: boolean) => void;
    validator: FormValidator;
    disabled?: boolean;
    expanded?: boolean;
}

const SecondaryHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const AccordionPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

export default function PredefinedSearchAccordion(props: IPredefinedSearchAccordionProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaGeneral',
    ]);

    const [dropdownItems, setDropdownItems] = useState<ADropdownItemProps[]>([]);
    const [searchType, setSearchType] = useState<number>(-1);
    const [expanded, setExpanded] = useState<boolean>(props.expanded ?? false);

    useEffect(() => {
        setDropdownItems(constructDropdownItems(props.searchGroup));
    }, [props.data.Parameters]);

    useEffect(() => {
        setSearchType(Number(dropdownItems[0]?.value) ?? 0);
    }, [dropdownItems]);

    const parameterFilter = (type: SearchType) => (parameter: ISearchParameter) => {
        if (type == SearchType.AnnouncementWeekOption) {
            return parameter.Type !== type && notAnAnnouncementWeekValue(parameter);
        }

        return parameter.Type !== type;
    };

    const deleteParameter = (type: SearchType) => {
        let id = '';

        if (isMultiselect(type))
            id =
                'multiselect-for-' +
                SearchTypeFromIntToString(
                    type,
                    props.parameters,
                    props.personParameters,
                    props.announcementParameters
                );
        else if (isRadiobuton(type))
            id =
                'radiobuttons-for-' +
                SearchTypeFromIntToString(
                    type,
                    props.parameters,
                    props.personParameters,
                    props.announcementParameters
                );
        else if (type === SearchType.AnnouncementWeekOption) {
            id = 'week-select-value-1-dropdown-announcement-week-search-parameter';
            props.validator.setState(id, true);
            id = 'week-select-operation-dropdown-announcement-week-search-parameter';
            props.validator.setState(id, true);
            id = 'week-select-value-2-dropdown-announcement-week-search-parameter';
        }

        props.validator.setState(id, true);

        props.searchChanged({
            ...props.data,
            Parameters: props.data.Parameters.filter(parameterFilter(type)),
        });
    };

    const constructDropdownItems = (searchGroup: SearchGroup): ADropdownItemProps[] => {
        let searchCriteria: string;
        if (searchGroup === SearchGroup.Place) searchCriteria = 'CommonSearchCriteria';
        if (searchGroup === SearchGroup.Person) searchCriteria = 'PersonSearchCriteria';
        if (searchGroup === SearchGroup.Announcements)
            searchCriteria = 'AnnouncementSearchCriteria';
        return (
            props.parameters
                .find((item) => item.name === searchCriteria)
                ?.options.map<ADropdownItemProps>((_) => {
                    return {
                        text: _.text,
                        value: getTypeFromCode(_.code, searchGroup),
                        disabled: getTypeFromCode(_.code, searchGroup) === SearchType.Unknown,
                    };
                })
                .filter(
                    (_) =>
                        props.data.Parameters.findIndex(
                            (parameter) => parameter.Type == _.value
                        ) === -1
                ) ?? []
        );
    };

    const parameterChanged = (parameter: ISearchParameter) => {
        const newParameters: ISearchParameter[] = [];

        for (const p of props.data.Parameters) {
            if (p.Group === parameter.Group && p.Type === parameter.Type) {
                newParameters.push({
                    Group: p.Group,
                    Type: p.Type,
                    Value: parameter.Value,
                });
            } else
                newParameters.push({
                    Group: p.Group,
                    Type: p.Type,
                    Value: p.Value,
                });
        }

        props.searchChanged({
            ...props.data,
            Parameters: newParameters,
        });
    };

    const announcementWeekParameterChanged = (parameter: ISearchParameter) => {
        const newParameters: ISearchParameter[] = props.data.Parameters.filter(
            (p) => notAnAnnouncementWeekValue(p) && p.Type !== SearchType.AnnouncementWeekOption
        );

        newParameters.push({
            Group: parameter.Group,
            Type: SearchType.AnnouncementWeekOption,
            Value: GetValueToSave(
                SearchType.AnnouncementWeekOption,
                parameter.Value,
                props.parameters,
                props.personParameters,
                props.announcementParameters
            ),
        });

        newParameters.push({
            Group: parameter.Group,
            Type: SearchType.AnnouncementWeekValue,
            Value: GetValueToSave(
                SearchType.AnnouncementWeekValue,
                parameter.Value,
                props.parameters,
                props.personParameters,
                props.announcementParameters
            ),
        });

        if (parameter.Value.length > 2)
            newParameters.push({
                Group: parameter.Group,
                Type: SearchType.AnnouncementWeekOptionalValue,
                Value: GetValueToSave(
                    SearchType.AnnouncementWeekOptionalValue,
                    parameter.Value,
                    props.parameters,
                    props.personParameters,
                    props.announcementParameters
                ),
            });

        props.searchChanged({
            ...props.data,
            Parameters: newParameters,
        });
    };

    const searchGroupLimits = (
        searchGroup: SearchGroup,
        usePersonsConnectedToTheFarms: boolean
    ) => {
        if (searchGroup !== SearchGroup.Person) return true;

        if (searchGroup === SearchGroup.Person && usePersonsConnectedToTheFarms) return true;
        else return false;
    };

    const disableToAddSearchCriteria = (
        usePersonsConnectedToTheFarms: boolean,
        searchGroup: SearchGroup,
        numberOfDropdownItems: number
    ) => {
        if (!usePersonsConnectedToTheFarms && searchGroup === SearchGroup.Person) return true;
        if (
            usePersonsConnectedToTheFarms &&
            searchGroup === SearchGroup.Person &&
            numberOfDropdownItems === 0
        )
            return true;
        return false;
    };

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded((previous) => !previous)}
                disabled={props.disabled}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                >
                    <Typography sx={{ flexBasis: '33.33%', flexShrink: 0 }}>
                        {props.title}
                    </Typography>
                    <SecondaryHeading>
                        {props.data.GUID !== '' &&
                        props.data.Parameters.some((e) => e.Group === props.searchGroup)
                            ? props.data.Parameters.filter(
                                  (_) =>
                                      _.Group === props.searchGroup &&
                                      searchGroupLimits(
                                          props.searchGroup,
                                          props.usePersonsConnectedToTheFarm
                                      ) &&
                                      notAnAnnouncementWeekValue(_)
                              )
                                  .map<string>(
                                      (p) =>
                                          SearchTypeFromIntToString(
                                              p.Type,
                                              props.parameters,
                                              props.personParameters,
                                              props.announcementParameters
                                          ) +
                                          (p.Type !== SearchType.AnnouncementWeekOption
                                              ? ' = '
                                              : ' ') +
                                          GetValueToShow(
                                              p.Type,
                                              p.Type !== SearchType.AnnouncementWeekOption
                                                  ? p.Value
                                                  : GetAnnouncementWeekValue(
                                                        props.data.Parameters,
                                                        p
                                                    ),
                                              props.parameters,
                                              props.personParameters,
                                              props.announcementParameters
                                          ).join(', ')
                                  )
                                  .join('; ')
                            : props.placeholder}
                    </SecondaryHeading>
                </AccordionSummary>
                <AccordionDetails>
                    <Container>
                        <Grid container direction='row' spacing={3}>
                            {props.searchGroup === SearchGroup.Person ? (
                                <Grid item>
                                    <ASwitch
                                        checked={props.usePersonsConnectedToTheFarm}
                                        id='use-persons-connected-to-the-farm'
                                        label={t('AnelmaCommunication:1127')}
                                        onChange={() =>
                                            props.setUsePersonsConnectedToTheFarm(
                                                !props.usePersonsConnectedToTheFarm
                                            )
                                        }
                                        validator={props.validator}
                                        withoutContainer
                                    />
                                </Grid>
                            ) : null}
                            {props.data.Parameters.filter(
                                (_) =>
                                    _.Group === props.searchGroup &&
                                    searchGroupLimits(
                                        props.searchGroup,
                                        props.usePersonsConnectedToTheFarm
                                    ) &&
                                    notAnAnnouncementWeekValue(_)
                            ).map((p) => {
                                return (
                                    <Grid key={p.Type} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <AccordionPaper>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    sx={{ textAlign: 'left' }}
                                                    item
                                                    xs={10}
                                                    sm={10}
                                                    md={10}
                                                    lg={10}
                                                    xl={10}
                                                >
                                                    <Parameter
                                                        type={p.Type}
                                                        value={
                                                            p.Type !==
                                                            SearchType.AnnouncementWeekOption
                                                                ? p.Value
                                                                : GetAnnouncementWeekValue(
                                                                      props.data.Parameters,
                                                                      p
                                                                  )
                                                        }
                                                        onChange={(v: string[]) => {
                                                            p.Type !=
                                                            SearchType.AnnouncementWeekOption
                                                                ? parameterChanged({
                                                                      Group: props.searchGroup,
                                                                      Type: p.Type,
                                                                      Value: v,
                                                                  })
                                                                : announcementWeekParameterChanged({
                                                                      Group: props.searchGroup,
                                                                      Type: p.Type,
                                                                      Value: v,
                                                                  });
                                                        }}
                                                        validator={props.validator}
                                                        parameters={props.parameters}
                                                        personParameters={props.personParameters}
                                                        announcementParameters={
                                                            props.announcementParameters
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <IconButton
                                                        aria-label='close'
                                                        onClick={() => {
                                                            deleteParameter(p.Type);
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </AccordionPaper>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid container sx={{ marginTop: '20px' }}>
                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <ADropdown
                                    id={'add-parameter-dropdown'}
                                    items={dropdownItems}
                                    validator={props.validator}
                                    style={{ minWidth: '160px' }}
                                    value={searchType === -1 || isNaN(searchType) ? '' : searchType}
                                    label={t('AnelmaCommunication:1040')}
                                    onChange={(e) => setSearchType(e as number)}
                                    disabled={disableToAddSearchCriteria(
                                        props.usePersonsConnectedToTheFarm,
                                        props.searchGroup,
                                        dropdownItems.length
                                    )}
                                    withoutContainer
                                />
                            </Grid>

                            <Grid
                                item
                                sx={{ display: 'flex', alignItems: 'end' }}
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                            >
                                <AAddButton
                                    onClick={() =>
                                        props.searchChanged({
                                            ...props.data,
                                            Parameters: [
                                                ...props.data.Parameters,
                                                {
                                                    Group: props.searchGroup,
                                                    Type: searchType,
                                                    Value: [],
                                                },
                                            ],
                                        })
                                    }
                                    size='small'
                                    type='action'
                                    disabled={disableToAddSearchCriteria(
                                        props.usePersonsConnectedToTheFarm,
                                        props.searchGroup,
                                        dropdownItems.length
                                    )}
                                >
                                    {t('AnelmaGeneral:1072')}
                                </AAddButton>
                            </Grid>
                        </Grid>
                    </Container>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
