import { IMainMenuItem } from "../mainMenu/IMainMenu";

import { RoutePaths } from '../../../../interfaces/enums';

import ACarboLinkButton from '../../../buttons/ACarboLinkButton';

export const responsibility: IMainMenuItem = {
    textCode: 1089,
    compact: true,
    itemGroups: [
        {
            headerCode: null,
            items: [
                {
                    resource: 'AnelmaResponsibility',
                    scope: 'LIST',
                    textCode: 1090,
                    externalLink: true,
                    hrefElement: ACarboLinkButton
                }
            ]
        }
    ],
};