// Libraries
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
// Core
import { ResourceTextApplication } from '../../../core/resources';

// Common
import ViewLoader from '../../../common/ViewLoader';

// Interfaces
import { RoutePaths } from '../../../interfaces/enums';
import { IFeedback } from '../../../interfaces/communication/feedback/IFeedback';
import { IFeedbackNotificationData } from '../../../interfaces/communication/feedback/IFeedbackNotificationData';

// Store
// API
import feedbackApi from '../../../api/messaging/feedbackApi';

// Feature - Feedback
import FeedbackDialog from '../FeedbackDialog';
import { FeedbackDialogContext } from '../../context/feedback/IFeedbackDialogContext';

export default function FeedbackDialogNotification() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<IFeedback | null>(null);
    const [feedbackNotificationData] = useState<IFeedbackNotificationData | null>(
        getFeedbackNotificationData()
    );

    useEffect(() => {
        if (feedbackNotificationData) getFeedback(feedbackNotificationData.FeedbackId);
    }, [feedbackNotificationData]);

    const getFeedback = (id: number) => {
        setLoading(true);
        feedbackApi.getFeedback(id).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaCommunication:1167'), { variant: 'error' });
                setLoading(false);
                return;
            }
            setFeedback(response.Entity);
            setLoading(false);
        });
    };

    function getFeedbackNotificationData() {
        return location.state !== null && location.state.options.FeedbackId
            ? ({
                  FeedbackId: location.state.options.FeedbackId,
              } as IFeedbackNotificationData)
            : null;
    }

    return (
        <>
            {!loading && feedback ? (
                <FeedbackDialogContext.Provider
                    value={{
                        feedback: feedback,
                    }}
                >
                    <FeedbackDialog
                        onClose={() => navigate(RoutePaths.GeneralFeedback)}
                        formMode={'modify'}
                    />
                </FeedbackDialogContext.Provider>
            ) : (
                <ViewLoader />
            )}
        </>
    );
}
