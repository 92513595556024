// Libraries
import { createContext } from 'react';

// MUI
// Core
// Common
// Interfaces
import { IFeedbackSummary } from '../../../interfaces/communication/feedback/IFeedbackSummary';
import { IFeedback } from '../../../interfaces/communication/feedback/IFeedback';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';
import { IAnimalPayloadItem, IAnimalSummary, ICompanyInfo } from '../../../interfaces/IAnimal';
import { CommunicationSubjectEnum } from '../../../interfaces/enums';
import { IFeedbackNotificationData } from '../../../interfaces/communication/feedback/IFeedbackNotificationData';

// Store
// API
// Feature - Feedback

interface IFeedbackViewContext {
    feedbackSummaries: IFeedbackSummary[];
    setToSummaries: (_: IFeedback) => void;
    feedbackViewLoading: boolean;
    farmDetails: IFarmDetails | null;
    updateToSummaries: (_: IFeedback) => void;
    preSelectedBovines?: IAnimalSummary[];
    preSelectedSubject?: CommunicationSubjectEnum;
    payloadData?: IAnimalPayloadItem;
    payloadFarms?: ICompanyInfo[];
    feedbackNotificationData?: IFeedbackNotificationData;
}

export const FeedbackViewContext = createContext<IFeedbackViewContext>({
    feedbackSummaries: [],
    setToSummaries: (_: IFeedback) => {},
    feedbackViewLoading: false,
    farmDetails: null,
    updateToSummaries: (_: IFeedback) => {},
    preSelectedBovines: [],
    payloadFarms: [],
});
