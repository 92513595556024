// Libraries
import { startTransition, useEffect, useState } from 'react';

// MUI
import { Drawer, Divider, List } from '@mui/material';

// Core
import auth from '../../../../core/authorization';

// Calender
import Agenda from '../../../../calendar/Agenda';

// Feature
import './userMenu.scss';
import OpenUserMenu from './OpenUserMenu';
import UserInfo from './UserInfo';
import UserMenuLinks from './UserMenuLinks';
import CultureSelector from './CultureSelector';

export interface UserMenuProps {
    closeSubMenu: () => void;
    mobile: boolean;
    open: boolean;
    setMenuOpen: (open: boolean) => void;
}

export default function UserMenu(props: UserMenuProps) {
    // AN-1982 - React v18 huomiot
    // Not the best option, because 2 renders happens, but doesn't work otherwise
    // Most probbably mui problem
    const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setDrawerIsOpen(props.open);
    }, [props.open]);
    // AN-1982 end.

    return (
        <div className='an-user-menu'>
            <OpenUserMenu
                mobile={props.mobile}
                openUserMenu={() => {
                    props.closeSubMenu();

                    // slows down menu opening.
                    // normal call will start too soon
                    // and closeSubMenu wouldn't affect.
                    // see React 18 for ref.
                    startTransition(() => {
                        props.setMenuOpen(true);
                    });
                }}
            />

            <Drawer
                className='an-user-menu-drawer'
                anchor='right'
                onClose={() => props.setMenuOpen(false)}
                open={drawerIsOpen}
            >
                <List>
                    <UserInfo />

                    <Divider />

                    <UserMenuLinks closeUserMenu={() => props.setMenuOpen(false)} />

                    <Divider />

                    <CultureSelector />

                    {auth.canViewCalendar && (
                        <>
                            <Divider />
                            {/* removed agenda because of load times, left component incase it will be needed later AN-2532 */}
                            {/* <Agenda closeUserMenu={() => props.setMenuOpen(false)} /> */}
                        </>
                    )}
                </List>
            </Drawer>
        </div>
    );
}
