// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import { ParameterValue, paramRepository, ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Common
import ViewLoader from '../common/ViewLoader';
import { ViewTitle } from '../common/typography';
import { AAddButton } from '../common/buttons';

// Interfaces
import { IPredefinedSearch, SearchType } from '../interfaces/ISearchParameters';

// API
import api from '../api/searchParametersApi';

// Feature
import {
    SearchTypeFromIntToString,
    GetValueToShow,
    GetAnnouncementWeekValue,
} from './Helpers/Parsers';
import SearchParametersDialog from './components/dialog/SearchParameterDialog';
import SearchParametersView from './components/data-grid/SearchParametersView';
import { IParameterExtension, ISearchParameterRow } from './shared/styles/types/subTypes';
import { notAnAnnouncementWeekValue } from './Helpers/general';

export default function SearchParameters(): JSX.Element {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaCommunication',
    ]);

    const [predefinedSearches, setPredefinedSearches] = useState<IPredefinedSearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchList, setSearchList] = useState<ISearchParameterRow[]>([]);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<IPredefinedSearch>();
    const [parameters, setParameters] = useState<IParameterExtension[]>([]);
    const [personParameters, setPersonParameters] = useState<IParameterExtension[]>([]);
    const [announcementParameters, setAnnouncementParameters] = useState<IParameterExtension[]>([]);

    useEffect(() => {
        setIsLoading(true);
        paramRepository
            .load([
                'AnelmaCommunication',
                'AnelmaGeneral',
                'AnelmaCompany',
                'AnelmaBovine',
                'AnelmaAnimalAnnouncement',
            ])
            .then(() => {
                let params: IParameterExtension[] = [];
                params.push({
                    name: 'CommonSearchCriteria',
                    options: paramRepository.resource(
                        'AnelmaCommunication',
                        'CommonSearchCriteria'
                    ),
                });
                params.push({
                    name: 'Regions',
                    options: paramRepository.resource('AnelmaGeneral', 'Regions'),
                });
                params.push({
                    name: 'Municipality',
                    options: paramRepository.resource('AnelmaGeneral', 'Municipality'),
                });
                params.push({
                    name: 'FarmStatus',
                    options: paramRepository.resource('AnelmaCommunication', 'FarmStatus'),
                });
                params.push({
                    name: 'Species',
                    options: paramRepository.resource('AnelmaCompany', 'Species'),
                });
                params.push({
                    name: 'MailPermission',
                    options: paramRepository.resource('AnelmaCommunication', 'MailPermission'),
                });
                params.push({
                    name: 'Language',
                    options: paramRepository.resource('AnelmaGeneral', 'Language'),
                });
                params.push({
                    name: 'FeedSupplier',
                    options: paramRepository.resource('AnelmaCompany', 'FeedSupplier'),
                });
                params.push({
                    name: 'Dairy',
                    options: paramRepository.resource('AnelmaCompany', 'Dairy'),
                });
                params.push({
                    name: 'FarmingTypeBovine',
                    options: paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine'),
                });
                params.push({
                    name: 'FarmingTypePig',
                    options: paramRepository.resource('AnelmaCompany', 'FarmingTypePig'),
                });
                setParameters(params);

                let paramsForPersons: IParameterExtension[] = [];
                paramsForPersons.push({
                    name: 'PersonSearchCriteria',
                    options: paramRepository.resource(
                        'AnelmaCommunication',
                        'PersonSearchCriteria'
                    ),
                });
                paramsForPersons.push({
                    name: 'Language',
                    options: paramRepository.resource('AnelmaGeneral', 'Language'),
                });
                paramsForPersons.push({
                    name: 'Titles',
                    options: paramRepository.resource('AnelmaGeneral', 'CommunicationGroupTitles'),
                });
                paramsForPersons.push({
                    name: 'PhoneNumberTypePerson',
                    options: paramRepository.resource('AnelmaGeneral', 'PhoneNumberTypePerson'),
                });
                setPersonParameters(paramsForPersons);

                let paramsForAnnouncements: IParameterExtension[] = [];
                paramsForAnnouncements.push({
                    name: 'AnnouncementSearchCriteria',
                    options: paramRepository.resource(
                        'AnelmaCommunication',
                        'AnnouncementSearchCriteria'
                    ),
                });
                paramsForAnnouncements.push({
                    name: 'Status',
                    options: paramRepository.resource(
                        'AnelmaAnimalAnnouncement',
                        'AnimalLoadStatus'
                    ),
                });
                paramsForAnnouncements.push({
                    name: 'Type',
                    options: paramRepository.resource(
                        'AnelmaAnimalAnnouncement',
                        'AnimalAnnouncementType'
                    ),
                });
                paramsForAnnouncements.push({
                    name: 'SalesType',
                    options: paramRepository.resource('AnelmaBovine', 'SalesType'),
                });
                paramsForAnnouncements.push({
                    name: 'Week',
                    options: paramRepository.resource(
                        'AnelmaCommunication',
                        'AnnouncementWeekSearchOptions'
                    ),
                });
                setAnnouncementParameters(paramsForAnnouncements);
            });
    }, []);

    useEffect(() => {
        if (parameters.length > 0) search().then(() => setIsLoading(false));
    }, [parameters]);

    const search = async () => {
        await api.getAllPredefinedSearches().then((data) => {
            if (!data) return;
            setPredefinedSearches(data?.Items);
            setSearchList(
                data?.Items.map<ISearchParameterRow>((d) => {
                    return {
                        guid: d.GUID,
                        name: d.Name,
                        filters:
                            d.Parameters.filter((p) => notAnAnnouncementWeekValue(p))
                                .map<string>(
                                    (p) =>
                                        SearchTypeFromIntToString(
                                            p.Type,
                                            parameters,
                                            personParameters,
                                            announcementParameters
                                        ) +
                                        ' = ' +
                                        GetValueToShow(
                                            p.Type,
                                            p.Type !== SearchType.AnnouncementWeekOption
                                                ? p.Value
                                                : GetAnnouncementWeekValue(d.Parameters, p),
                                            parameters,
                                            personParameters,
                                            announcementParameters
                                        ).join(', ')
                                )
                                .join('; ') ?? '',
                    };
                })
            );
            setIsLoading(false);
        });
    };

    const openEditDialog = (row: ISearchParameterRow) => {
        setDialogData(predefinedSearches.find((s) => s.GUID === row.guid));
        setDialogVisible(true);
    };

    const onClose = () => {
        search();
        setDialogData(undefined);
        setDialogVisible(false);
    };

    return (
        <Container data-robot-id={'app-body-management-search-parameters'}>
            <ViewTitle>{t(`AnelmaLayout:1012`)}</ViewTitle>

            {auth.canCreateCommunicationGroups && (
                <Grid container justifyContent='flex-end'>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ textAlign: 'right', minWidth: '250px' }}
                    >
                        <AAddButton onClick={() => setDialogVisible(true)} type={'action'}>
                            {t('AnelmaCommunication:1035')}
                        </AAddButton>
                    </Grid>
                </Grid>
            )}

            {!isLoading ? (
                <SearchParametersView
                    data={searchList}
                    openEdit={(row) => openEditDialog(row)}
                    delete={(row) => api.deletePredefinedSearch(row.guid).then((_) => search())}
                />
            ) : (
                <ViewLoader />
            )}

            {dialogVisible && (
                <SearchParametersDialog
                    onClose={() => onClose()}
                    mode={dialogData ? 'modify' : 'create'}
                    parameters={parameters}
                    personParameters={personParameters}
                    announcementParameters={announcementParameters}
                    data={dialogData ? dialogData : undefined}
                />
            )}
        </Container>
    );
}
