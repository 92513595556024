import { useState, useEffect } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import moment from 'moment';

// MUI
//Core
import { ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// Interfaces
import { IAccounting, IProducerInfo } from '../../interfaces/IAccounting';

// Common

import ViewLoader from '../../common/ViewLoader';
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { ViewTitle } from '../../common/typography';

// API
import AccountingApi from '../../api/accountingApi';

//Feature
import { ADefaultIconButton, AFindInPageIconButton } from '../../common/buttons';
import AccountingDetailView from './AccountingDetailView';
import InvoiceMathHelper from '../invoices/InvoiceMathHelper';
import { PictureAsPdf } from '@mui/icons-material';
import printingApi from '../../api/printingApi';
import { useSnackbar } from 'notistack';

export interface AccountingListViewProps {
    contextData: IProducerInfo;
}

export default function AccountingListView(props: AccountingListViewProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { contextData } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout', 'AnelmaAccounting']);
    const [rowsData, setRowsData] = useState<IAccounting[]>([]);
    const rows = rowsData
        .map((i) => mergeRight(i, { id: i.PaymentNumber }))
        .sort((a, b) => (a.PaymentNumber < b.PaymentNumber ? 1 : -1));

    enum ColumnsWidth {
        minSize = 80,
        midSize = 120,
        maxSize = 150,
    }
    const columns: AGridColumns = [
        {
            field: 'PaymentNumber',
            headerName: t('AnelmaAccounting:1007'),
            type: 'string',
            width: ColumnsWidth.midSize,
        },
        gridColumns.dateTime(
            'CollectingDate',
            t('AnelmaAccounting:1005'),
            'DD.MM.YYYY',
            ColumnsWidth.midSize
        ),
        gridColumns.dateTime(
            'PaymentDate',
            t('AnelmaAccounting:1006'),
            'DD.MM.YYYY',
            ColumnsWidth.midSize
        ),

        {
            field: 'PriceBeforeTaxes',
            headerName: t('AnelmaAccounting:1008'),
            description: t('AnelmaAccounting:1009'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },

        {
            field: 'VATAmount',
            headerName: t('AnelmaAccounting:1010'),
            description: t('AnelmaAccounting:1010'),
            type: 'number',
            width: ColumnsWidth.midSize - 30,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceAfterTaxes',
            headerName: t('AnelmaAccounting:1012'),
            description: t('AnelmaAccounting:1013'),
            type: 'number',
            width: ColumnsWidth.midSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'RemovalPriceBeforeTaxes',
            headerName: t('AnelmaAccounting:1014'),
            description: t('AnelmaAccounting:1015'),
            type: 'number',
            width: ColumnsWidth.maxSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'RemovalVATAmount',
            headerName: t('AnelmaAccounting:1016'),
            description: t('AnelmaAccounting:1017'),
            type: 'number',
            width: ColumnsWidth.maxSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PaymentSum',
            headerName: t('AnelmaAccounting:1018'),
            description: t('AnelmaAccounting:1019'),
            type: 'number',
            width: ColumnsWidth.maxSize - 10,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
    ];

    const [selectedRow, setSelectedRow] = useState<IAccounting>();
    const [accountingDetailVisible, setAccountingDetailVisible] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(false);
        initialData();
    }, [contextData?.ProducerNumber]);

    const initialData = () => {
        if (contextData?.ProducerNumber !== 0) {
            AccountingApi.getProducerNumberAccounting(contextData?.ProducerNumber as number).then(
                (response) => {
                    if (response.Items.length === 0 || !response) return;
                    setRowsData(response.Items);
                },
                () => {
                    return;
                }
            );
        }
        setRowsData([]);
        setIsLoading(true);
    };

    const handleAccountingDialog = (item: IAccounting) => {
        setAccountingDetailVisible(!accountingDetailVisible);
        setSelectedRow(item);
    };

    const getPrintPaymentAction = (paymentNumber: number) => {
        return (
            auth.canViewEconomyAccounting && (
                <ADefaultIconButton
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        printingApi.printPayment(paymentNumber).then((response) => {
                            if (!response) {
                                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                                    variant: 'error',
                                });
                            }
                        });
                    }}
                    tooltip=""
                />
            )
        );
    }

    return (
        <>
            {isLoading ? (
                auth.canViewEconomyAccounting && (
                    <>
                        <ViewTitle>{t('AnelmaLayout:1056')}</ViewTitle>
                        <ADataGrid
                            actions={[
                                (params) => {
                                    const item = params.row as unknown as IAccounting;
                                    return (
                                        auth.canViewEconomyAccounting && (
                                            <AFindInPageIconButton
                                                key={`${params.id}-view`}
                                                tooltip={t('AnelmaAccounting:1021')}
                                                onClick={() => {
                                                    handleAccountingDialog(item);
                                                }}
                                            />
                                        )
                                    );
                                },
                                (params) =>
                                    getPrintPaymentAction(
                                        (params.row as unknown as IAccounting).PaymentNumber
                                    ),
                            ]}
                            columns={columns}
                            rows={rows.length ? rows : []}
                            enableFiltering
                            enablePagination
                        ></ADataGrid>
                    </>
                )
            ) : (
                <ViewLoader />
            )}

            {accountingDetailVisible && (
                <AccountingDetailView
                    key={'detail-view'}
                    contextData={contextData as IProducerInfo}
                    data={selectedRow as IAccounting}
                    onClose={() => {
                        setAccountingDetailVisible(!accountingDetailVisible);
                    }}
                />
            )}
        </>
    );
}
