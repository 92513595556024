// Libraries
import { ForwardedRef, useEffect, useState } from 'react';

// Core
import { IFormValidator } from '../../../core/FormValidator';
import { ParameterValue } from '../../../core/resources';

// Common
import {
    AInputContainer,
    ADropdown,
    ADropdownValue,
    ADropdownItemProps,
    ALabel,
} from '../../../common/inputs';
import { AnnouncementWeekIsBetweenTwoDatesSearchParameterCode } from '../../Helpers/constants';

export interface AWeekInputInputProps {
    id: string;
    onChange: (value: string[]) => any;
    options: ParameterValue[];
    validator: IFormValidator;
    selectedOption?: string[];
    error?: boolean;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    withoutContainer?: boolean;
}

export default function AWeekInput(props: AWeekInputInputProps, ref: ForwardedRef<any>) {
    const optionFromCode = (opt?: string[]) => {
        if (opt && opt.length > 0 && opt[0] !== null) {
            let i = options.findIndex((o) => o.code === opt[0]);

            if (i != -1) {
                return options[i];
            }
        }

        return null;
    };

    const {
        error,
        disabled,
        id,
        label,
        onChange,
        required,
        validator,
        options,
        selectedOption,
        withoutContainer,
        ...propsForDatePicker
    } = props;

    const [touched, setTouched] = useState<boolean>(false);
    const [opt, setOption] = useState<ParameterValue | null>(optionFromCode(selectedOption));
    const [leftVal, setLeftVal] = useState<string>('');
    const [rightVal, setRightVal] = useState<string>('');
    const [err, setErr] = useState<boolean>(false);

    useEffect(() => {
        if (selectedOption) {
            setOption(optionFromCode(selectedOption));
            setRightVal(selectedOption[1]);

            if (selectedOption.length > 2) setLeftVal(selectedOption[2]);
        }
    }, [selectedOption]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && opt === null) return false;
            if (required && (rightVal === null || rightVal === '')) return false;
            if (
                required &&
                opt?.code === AnnouncementWeekIsBetweenTwoDatesSearchParameterCode.toString() &&
                (leftVal === null || leftVal === '')
            )
                return false;

            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, leftVal, rightVal, validator]);

    const optionsList: () => ADropdownItemProps[] = () => {
        return options.map((o) => ({ text: o.text, value: o.code }));
    };

    const weeks: ADropdownItemProps[] = Array.from(
        { length: 52 },
        (_, i) => i + 1
    ).map<ADropdownItemProps>((i) => ({
        text: i.toString(),
        value: i.toString(),
    }));

    const handleChange = (option: ParameterValue | null, rightValue: string, leftValue: string) => {
        if (option !== null && rightValue !== null) {
            if (
                option.code === AnnouncementWeekIsBetweenTwoDatesSearchParameterCode.toString() &&
                leftValue !== null
            )
                onChange([option.code, rightValue, leftValue]);
            else onChange([option.code, rightValue]);
        }
    };

    const optionChanged = (v: ADropdownValue) => {
        let optIndex = options.findIndex((o) => o.code === v);

        if (optIndex != -1) {
            handleChange(options[optIndex], rightVal, leftVal);
        }
    };

    const rightValueChanged = (v: ADropdownValue) => {
        if (v != null) {
            handleChange(opt, v.toString(), leftVal);
        }
    };

    const leftValueChanged = (v: ADropdownValue) => {
        if (v != null) {
            handleChange(opt, rightVal, v.toString());
        }
    };

    const dropdown = (
        <>
            {props.label && <ALabel>{props.label}</ALabel>}

            {opt?.code === AnnouncementWeekIsBetweenTwoDatesSearchParameterCode.toString() && (
                <ADropdown
                    id={'week-select-value-1-dropdown-' + id}
                    onChange={(v) => {
                        leftValueChanged(v);
                    }}
                    value={leftVal}
                    items={weeks}
                    validator={validator}
                    style={{ minWidth: 100, marginRight: 30 }}
                    withoutContainer
                    required
                />
            )}

            <ADropdown
                id={'week-select-operation-dropdown-' + id}
                onChange={(v) => {
                    optionChanged(v);
                }}
                value={opt?.code ?? null}
                items={optionsList()}
                validator={validator}
                style={{ minWidth: 100, marginRight: 30 }}
                withoutContainer
                required
            />

            {opt !== null && (
                <ADropdown
                    id={'week-select-value-2-dropdown-' + id}
                    onChange={(v) => {
                        rightValueChanged(v);
                    }}
                    value={rightVal}
                    items={weeks}
                    validator={validator}
                    style={{ minWidth: 100 }}
                    withoutContainer
                    required
                />
            )}
        </>
    );

    return dropdown;
}
