export enum NotificationTypeEnum {
    Default = 0,
    CreateProductionPlanForNextYear = 1,
    NewMessageInNewConversation = 2,
    NewMessageInOldConversation = 3,
    NewFeedbackMessageInOldFeedbackItem = 4,
    FeedbackStatusChanged = 5,
    NewFeedback = 6,
    NewInvoice = 7,
    NewCreditReport = 8,
    NewCreditNote = 9,
    NewAnimalPickupPayloadSms = 10,
    NewAnimalArrivalPayloadSms = 11,
    NewFarmVisitTwoDaysBeforeStartDateToFarm = 12,
    NewFarmVisitTwoDaysBeforeStartDateToPerson = 13,
    NewCompany = 14, // http://jira.mtech.fi/browse/AN-1095 at this point used only for farms but who knows if in the near future will expand to traffic contractors
}

export interface INotificationResult {
    NotificationId: string;
    TypeId: NotificationTypeEnum;
    Flags?: NotificationFlagsEnum;
    ResourceTexts: INotificationResourceText[];
    Created: string;
    ReadDateTime?: string;
    DeletedDateTime?: string;
    Key: IKey;
    FarmId: string;
}

export interface INotificationResourceText {
    Text: string;
    Culture: string;
}

export interface IKey {
    NumbericKey?: number;
    UuidKey?: string;
}

export enum NotificationFlagsEnum {
    Initial = 1,
    Read = 2,
    Deleted = 3,
}

export interface IModifyNotifications {
    Items: IModifyNotificationsPayload[];
}

export interface IModifyNotificationsPayload {
    UserId: string;
    NotificationId: string;
    Flags: NotificationFlagsEnum;
    ReadDateTime: string | null;
    DeletedDateTime: string | null;
}
