// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { GridCellParams } from '@mui/x-data-grid-pro';

// Core
import { ResourceTextApplication } from '../../../core/resources';

// Common
import ADataGrid from '../../../common/dataGrid/ADataGrid';

// Interfaces
// Store
// API
// Feature
import { IProductionPlanDataGrid } from './ProductionPlanDataGrids';

interface IProps {
    data: IProductionPlanDataGrid | undefined;
}

export default function ProductionPlanAnimalsDataGrid(props: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCattleProductionPlan']);
    const renderCell = (params: GridCellParams) => {
        return params.value?.toString() ?? '';
    };

    if (!props.data) return <></>;

    return (
        <ADataGrid
            columns={props.data.columns.map((x) => {
                x.headerName = t(x.headerName || '');
                return {
                    renderCell: renderCell,
                    ...x,
                };
            })}
            rows={props.data.rows.map((x) => {
                x.title = t(x.title);
                return x;
            })}
        />
    );
}
