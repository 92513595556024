// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';

// Feature
import './subMenu.scss';
import { IMainMenuItem, ISubMenuGroup } from '../mainMenu/IMainMenu';
import SubMenuItem from './SubMenuItem';
import { useState } from 'react';

export interface SubMenuProps {
    closeSubMenu: () => void;
    menuItem: IMainMenuItem;
}

export default function SubMenu(props: SubMenuProps) {
    const { t } = useTranslation('AnelmaLayout');

    const { closeSubMenu, menuItem } = props;

    const [className] = useState<string>([
        'an-sub-menu',
        menuItem.compact ? 'compact' : ''
    ].filter(x => x !== '').join(' '));

    return (
        <div className={className}>
            <Grid className='an-sub-menu-grid'>
                {menuItem.itemGroups.map((group, index2) => (
                    <Grid className='an-sub-menu-group' item key={index2} data-robot-id={"sub-menu-group-"+index2}>
                        <List data-robot-id={"sub-menu-group-"+index2 + "-nav"}
                            key={index2}
                            component='nav'
                            subheader={
                                group.headerCode ? (
                                    <Typography variant='h4' data-robot-id={"sub-menu-group-"+index2 + "-nav" +  "-typography-"+group.headerCode}>
                                        {t(`AnelmaLayout:${group.headerCode}`)}
                                    </Typography>
                                ) : undefined
                            }
                        >
                            {group.items.map((item, index3) => (
                                <ListItem key={index3} data-robot-id={"sub-menu-group-"+index2 + "-nav" + "-list-item-" + index3}>
                                    <ListItemText>
                                        <SubMenuItem closeSubMenu={closeSubMenu} item={item} 
                                        data-robot-id={"sub-menu-group-"+index2 + "-nav" + "-list-item-" + index3 +"-sub-item-" + item} />
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
