import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isNotNil } from 'ramda';
import { useLocation } from 'react-router-dom';

// MUI
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// Common
import { AAddButton } from '../../common/buttons';
import { ViewTitle } from '../../common/typography';
import { Context, contextControl } from '../../common/contextSelector/ContextSelector';

// Interfaces
import { IFeedbackSummary } from '../../interfaces/communication/feedback/IFeedbackSummary';
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import { IFeedback } from '../../interfaces/communication/feedback/IFeedback';
import { AliasTypeEnum } from '../../interfaces/enums';
import { IFeedbackNotificationData } from '../../interfaces/communication/feedback/IFeedbackNotificationData';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import feedbackApi from '../../api/messaging/feedbackApi';
import companyApi from '../../api/companyApi';

// Feature - Feedback
import { FeedbackViewContext } from '../context/feedback/IFeedbackViewContext';
import FeedbackDialog from './FeedbackDialog';
import FeedbackList from './FeedbackList';
import { mapToFeedbackSummaries } from './helpers/feedbackSummaryHelper';
import { SelectorConstants } from './constants/SelectorConstants';

export default function FeedbackView() {
    const context = useAppSelector((state) => state.context);
    const userData = useAppSelector((state) => state.userData.data);
    const location = useLocation();

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaLayout',
        'AnelmaSolmuErrors',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails | null>(null);
    const [feedbackSummaries, setFeedbackSummaries] = useState<IFeedbackSummary[]>([]);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (context?.data?.currentContext) getCurrentCompanyContext(context.data.currentContext);
    }, [context]);

    useEffect(() => {
        if (farmDetails?.Id) initializeFeedbackSummaries(farmDetails.Id);
    }, [farmDetails]);

    const getCurrentCompanyContext = (ctx: Context) => {
        setLoading(true);

        companyApi.getCompanyByContext(ctx.context).then((response) => {
            if (response?.Entity) {
                setFarmDetails(response.Entity as IFarmDetails);
                setLoading(false);
            } else
                enqueueSnackbar(`${t(`AnelmaSolmuErrors:10009`)}`, {
                    variant: 'error',
                });
            setLoading(false);
        });
    };

    const initializeFeedbackSummaries = (farmId: string) => {
        setLoading(true);

        feedbackApi.getFeedbackList(farmId, displayTransports()).then((_) => {
            if (_ === null) {
                enqueueSnackbar(t('AnelmaCommunication:1164'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setFeedbackSummaries(_?.Items);
            setLoading(false);
        });
    };

    const getAllFeedback = () => {
        setLoading(true);

        feedbackApi.getAllFeedback().then((_) => {
            if (_ === null) {
                enqueueSnackbar(t('AnelmaCommunication:1164'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setFeedbackSummaries(_?.Items);
            setLoading(false);
        });
    };

    const displayTransports = () => {
        if (userData.IsTrafficContractor || userData.IsDriver || userData.IsSnellmanUser)
            return true;
        return false;
    };

    /** When feedback is set read for participant X this function gets called to update the feedbackSummaries state.
     *
     * @param feedback IFeedback
     */
    const updateFeedbackSummaries = (feedback: IFeedback) => {
        const idx = feedbackSummaries.findIndex((item) => item.Id === feedback.Id);

        if (idx !== -1) {
            const updatedItems = [...feedbackSummaries];
            updatedItems[idx] = {
                ...updatedItems[idx],
                Participants: feedback.Participants,
                Status: feedback.Status,
                HandlerPerson: feedback.HandlerPerson,
                Reason: feedback.Reason,
                HasAttachments: isNotNil(feedback.Attachments) && feedback.Attachments.length > 0,
            };

            setFeedbackSummaries(updatedItems);
        }
    };

    /** Set new feedback to state and check if context selector's farm is different than in feedback set the farm to the one picked from the feedback.
     * @param feedback IFeedback */
    const handleSetToSummaries = (feedback: IFeedback) => {
        const senderCompany = feedback.SenderCompany;

        if (farmDetails && farmDetails.Id !== senderCompany.Id) {
            changeSelectedFarm(farmDetails, senderCompany.Id);
        } else {
            setFeedbackSummaries((previousState) => [
                mapToFeedbackSummaries(feedback),
                ...previousState,
            ]);
        }
    };

    const changeSelectedFarm = (farmDetails: IFarmDetails, senderCompanyId: string) => {
        setFeedbackSummaries([]);

        // Not farm's uuid
        const farmId = farmDetails.Aliases.find((x) => x.Type === AliasTypeEnum.FarmId)?.Value;

        setLoading(true);

        companyApi.getCompanyByGuid(senderCompanyId).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            // Not farm's uuid
            const feedbackFarmId = response.Entity.Aliases.find(
                (x) => x.Type === AliasTypeEnum.FarmId
            )?.Value;

            if (isNotNil(farmId) && isNotNil(feedbackFarmId) && farmId !== feedbackFarmId)
                contextControl.setFarm(feedbackFarmId);
        });
    };

    const toggleAllFeedbackView = (value: boolean) => {
        if (value) getAllFeedback();
        else if (value === false) initializeFeedbackSummaries(farmDetails?.Id ?? '');
    };

    const getFeedbackNotificationData = () => {
        return location.state !== null && location.state.options.FeedbackId
            ? ({
                  FeedbackId: location.state.options.FeedbackId,
              } as IFeedbackNotificationData)
            : undefined;
    };

    return (
        <Container data-robot-id={SelectorConstants.FeedbackViewContainer}>
            <ViewTitle>{t('AnelmaLayout:1013')}</ViewTitle>

            <FeedbackViewContext.Provider
                value={{
                    feedbackSummaries: feedbackSummaries,
                    setToSummaries: (_: IFeedback) => handleSetToSummaries(_),
                    feedbackViewLoading: loading,
                    farmDetails: farmDetails,
                    updateToSummaries: (_: IFeedback) => updateFeedbackSummaries(_),
                    feedbackNotificationData: getFeedbackNotificationData(),
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                        <AAddButton
                            data-robot-id={SelectorConstants.CreateFeedbackBtn}
                            onClick={() => setFeedbackDialogOpen(true)}
                            type='action'
                            disabled={!auth.canCreateFeedback || loading}
                        >
                            {t('AnelmaCommunication:1086')}
                        </AAddButton>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FeedbackList toggleAllFeedbackView={(_) => toggleAllFeedbackView(_)} />
                    </Grid>
                </Grid>

                {feedbackDialogOpen && (
                    <FeedbackDialog
                        onClose={() => setFeedbackDialogOpen(false)}
                        formMode={'create'}
                    />
                )}
            </FeedbackViewContext.Provider>
        </Container>
    );
}
