// Libraries
import { reduce, keys, equals, append, propOr } from 'ramda';

// Interface
import { FieldUpdate, IUpdatePayload, NamedField } from '../../interfaces/IGeneral';
import {
    IPredefinedSearch,
    ISearchParameter,
    SearchGroup,
    SearchType,
} from '../../interfaces/ISearchParameters';
import { IProductionLine } from '../../interfaces/IBusinessEntities';
import { AnimalTypeEnum } from '../../interfaces/enums';

export const getTypeFromCode = (code: string, searchGroup: SearchGroup) => {
    if (searchGroup === SearchGroup.Place) {
        switch (code) {
            case '0':
                return SearchType.ReturnExpiredOnes;
            case '1':
                return SearchType.Species;
            case '2':
                return SearchType.Municipality;
            case '3':
                return SearchType.County;
            case '4':
                return SearchType.Language;
            case '6':
                return SearchType.FeedSupplier;
            case '7':
                return SearchType.Dairy;
            case '8':
                return SearchType.FarmingTypeGeneric;
            default:
                return SearchType.Unknown;
        }
    } else if (searchGroup === SearchGroup.Person) {
        switch (code) {
            case '1':
                return SearchType.PersonLanguage;
            case '2':
                return SearchType.PersonTitle;
            case '3':
                return SearchType.PersonPhoneNumberType;
            default:
                return SearchType.Unknown;
        }
    } else if (searchGroup === SearchGroup.Announcements) {
        switch (code) {
            case '0':
                return SearchType.AnnouncementType;
            case '1':
                return SearchType.AnnouncementsStatus;
            case '2':
                return SearchType.AnnouncementSalesType;
            case '3':
                return SearchType.AnnouncementWeekOption;
            default:
                return SearchType.Unknown;
        }
    } else {
        return SearchType.Unknown;
    }
};

export const mapEditDataToRequest = (
    oldSearch: IPredefinedSearch,
    newSearch: IPredefinedSearch
): IUpdatePayload<IPredefinedSearch> => {
    let updates: FieldUpdate[] = [];
    let additions: NamedField[] = [];
    let deletions: NamedField[] = [];

    const fields = keys(newSearch);

    updates = reduce(
        (a: FieldUpdate[], key: string) => {
            // gets current and original values for a certain key
            const value = propOr(undefined, key, newSearch);
            const origValue = propOr(undefined, key, oldSearch);

            if (equals(value, origValue)) return a;

            return append(
                {
                    Field: key,
                    NewData: value,
                    OldData: origValue,
                },
                a
            );
        },
        [],
        fields
    );

    additions = reduce(
        (a: NamedField[], key: string) => {
            // gets current and original values for a certain key
            const value = propOr(undefined, key, newSearch);
            const origValue = propOr(undefined, key, oldSearch);

            if (origValue !== null || value === null) return a;

            return append(
                {
                    Field: key,
                    Data: value,
                },
                a
            );
        },
        [],
        fields
    );

    deletions = reduce(
        (a: NamedField[], key: string) => {
            // gets current and original values for a certain key
            const value = propOr(undefined, key, newSearch);
            const origValue = propOr(undefined, key, oldSearch);

            if (value !== null || origValue === null) return a;

            return append(
                {
                    Field: key,
                    Data: origValue,
                },
                a
            );
        },
        [],
        fields
    );

    return {
        Additions: additions,
        Deletions: deletions,
        Entity: oldSearch,
        Updates: updates,
    };
};

export const isUsingPersonsConnectedToTheFarm = (parameters: ISearchParameter[] | undefined) => {
    if (parameters == undefined) return false;
    for (const p of parameters) {
        if (p.Group === SearchGroup.Person) {
            return true;
        }
    }
    return false;
};

export const notAnAnnouncementWeekValue = (param: ISearchParameter) => {
    return (
        param.Type !== SearchType.AnnouncementWeekValue &&
        param.Type !== SearchType.AnnouncementWeekOptionalValue
    );
};
