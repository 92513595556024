// Libraries
import { ReactNode, useCallback, useState } from 'react';

// MUI
import { styled, Button, Typography } from '@mui/material';

// Common
import { AButtonBaseProps } from '../../common/buttons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import carboApi from '../../api/carboApi';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#0a4c31',
    borderRadius: 0,
    textTransform: 'none',
    border: 'none',
    paddingLeft: '15px',
    paddingRight: '15px'
}));

export default function ACarboLinkButton() {
    const userData = useAppSelector(state => state.userData.data);
    const { t } = useTranslation('AnelmaLayout');

    const [title] = useState<string>(t('AnelmaLayout:1090'));

    const onClick = useCallback(() => {
        var email = userData.EmailAddresses.length > 0 ? (
            userData.EmailAddresses.find(x => x.IsDefault)
            || userData.EmailAddresses[0]
        ).Value : '';
        var name = `${userData.FirstName} ${userData.LastName}`;
        carboApi.createLink(email, name).then(link => {
            window.open(link, "_blank");
        });
    }, [userData]);

    return <StyledButton title={title} onClick={onClick} variant='contained'>
        <Typography variant="h6" color="white">{title}</Typography>
    </StyledButton>;
}