export const CalendarEventStyles = {
    PickupTransMissionAnimal: {
        backgroundColor: '#F75050',
        color: '#FFFFFF',
    },
    PickupSlaughterAnimal: {
        backgroundColor: '#F75050',
        color: '#FFFFFF',
    },
    FarmVisit: {
        backgroundColor: '#3174ad',
        color: '#FFFFFF',
    },
    Event: {
        backgroundColor: '#0a4c31',
        color: '#FFFFFF',
    },
    EventRegistration: {
        backgroundColor: '#e8f1ef',
        color: '#000000',
    },
    OwnBookings: {
        backgroundColor: '#ffeb9c',
        color: '#000000',
    },
    Other: {
        backgroundColor: '#8FBC8F',
        color: '#FFFFFF',
    },
    ReligiousHolidays: {
        backgroundColor: '#737373',
        color: '#000000',
    },
};
