import axios from 'axios';
import { carboUrl } from './apiEndpoints';
import ApiBase from './ApiBase';

class CarboApi extends ApiBase {
    createLink(
        email: string,
        name: string
    ): Promise<string> {
        return axios
            .post(`${carboUrl}/CreateLink`, {
                userEmail: email,
                userName: name
            })
            .then((d) => {
                return d.data;
            });
    }
}

export default new CarboApi();