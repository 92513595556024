// MUI
import { Collapse, Container, Grid } from '@mui/material';

// Common
import { ACloseButton } from '../../../buttons';

// Feature
import './subMenu.scss';
import { IMainMenuItem, ISubMenuGroup } from '../mainMenu/IMainMenu';
import SubMenu from './SubMenu';
import { useEffect, useState } from 'react';

export interface SubMenuProps {
    closeSubMenu: () => void;
    menuItems: IMainMenuItem[];
    openMenuItem: number | null;
    touchMenu: boolean;
}

export default function SubMenuContainer(props: SubMenuProps) {
    // AN-1982 - React v18 huomiot
    // Not the best option, because 2 renders happens, but doesn't work otherwise
    // Most probbably mui problem
    const [openMenuItem, setOpenMenuItem] = useState<number | null>(props.openMenuItem);

    useEffect(() => {
        setOpenMenuItem(props.openMenuItem);
    }, [props.openMenuItem]);
    // AN-1982 end.

    return (
        <div className='an-sub-menu-container'>
            <Container>
                {props.openMenuItem !== null && props.touchMenu ? (
                    <Grid
                        container
                        justifyContent='center'
                        style={{ position: 'relative', top: '20px' }}
                    >
                        <Grid item>
                            <ACloseButton
                                onClick={props.closeSubMenu}
                                onTouchStart={props.closeSubMenu}
                            />
                        </Grid>
                    </Grid>
                ) : undefined}

                {props.menuItems.map((menuItem, index) =>
                    !menuItem.itemGroups || !menuItem.itemGroups.length ? null : (
                        <Collapse in={index === openMenuItem} key={index}>
                            <SubMenu
                                closeSubMenu={props.closeSubMenu}
                                menuItem={menuItem}
                            />
                        </Collapse>
                    )
                )}
            </Container>
        </div>
    );
}
