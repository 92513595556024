// Libraries
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

// MUI
import { Divider, Popover } from '@mui/material';
import { styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Common
import { ViewTitle } from '../common/typography';
import { ALabel } from '../common/inputs';
import { ConfirmDeletionDialog } from '../common/dialog';

// Interfaces
// Store
// API
// Feature - Notifications
import { NotificationViewContext } from './context/INotificationContext';
import NotificationBox from './notificationBox/NotificationBox';

const StyledPopover = styled(Popover)(({ theme }) => ({
    overflowY: 'auto',
    '.MuiPopover-paper': {
        height: '600px',
        width: '315px',
    },
}));

interface NotificationsListProps {
    anchor: Element;
}

/** Display notifications in a popover.
 *
 * @props anchor: Element
 */
export default function NotificationList(props: NotificationsListProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaNotifications',
    ]);

    const { notifications, setRead, deleteNotifications, restoreAnchor } =
        useContext(NotificationViewContext);

    const popoverOpen = Boolean(props.anchor);

    const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        if (popoverOpen) setNotificationsRead();
    }, []);

    const setNotificationsRead = () => {
        setTimeout(() => {
            const data = notifications.filter((_) => _.ReadDateTime === null).map((_) => _);
            if (data.length > 0) setRead(data);
        }, 2000);
    };

    const getDeleteLabelStyling = () => {
        return notifications.length === 0
            ? { color: '#0000FF' }
            : { color: '#0000FF', cursor: 'pointer' };
    };

    return (
        <>
            <StyledPopover
                anchorEl={props.anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={popoverOpen}
                onClose={restoreAnchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock={false}
            >
                <div style={{ margin: '5px 5px 0 5px' }}>
                    <ViewTitle>{t('AnelmaLayout:1080')}</ViewTitle>
                    {auth.canDeleteNotifications && (
                        <ALabel
                            onClick={() =>
                                notifications.length > 0
                                    ? setConfirmDeleteDialogVisible(true)
                                    : null
                            }
                            sx={getDeleteLabelStyling()}
                            disabled={notifications.length === 0}
                        >
                            {t('AnelmaNotifications:1000')}
                        </ALabel>
                    )}

                    <Divider />

                    <NotificationBox />
                </div>
            </StyledPopover>

            <ConfirmDeletionDialog
                open={confirmDeleteDialogVisible}
                onClose={() => setConfirmDeleteDialogVisible(false)}
                onDelete={() => deleteNotifications()}
                confirmation={t('AnelmaGeneral:1142')}
            />
        </>
    );
}
