export const getNotificationHubUrl = () => {
    const hubRelPath = 'notificationhub';

    switch (window.location.host) {
        case 'snellman-anelma3-dev.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-dev.mtech.fi/${hubRelPath}`;
        case 'snellman-anelma3-test.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-test.mtech.fi/${hubRelPath}`;
        case 'snellman-anelma3-demo.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-demo.mtech.fi/${hubRelPath}`;
        case 'anelma3.snellman.fi':
        case 'snellman-anelma3.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api.mtech.fi/${hubRelPath}`;
        default:
            return `${window.location.protocol}//${window.location.hostname}/Snellman.Notification.API.Presentation/${hubRelPath}`;
    }
};
