// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil, mergeRight } from 'ramda';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { CircularProgress, Container, Grid, Tooltip, Typography } from '@mui/material';
import { PictureAsPdf, PriorityHigh } from '@mui/icons-material';
import { gridFilteredSortedRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';
import { dayFormat } from '../../core/constants';
import auth from '../../core/authorization';
import Utils from '../../core/utils';

// Common
import { ADataGrid, AGridColumns, ADataGridActions } from '../../common/dataGrid';
import { ADefaultButton, ADefaultIconButton, ADeleteIconButton } from '../../common/buttons';
import { TextWithTooltip } from '../../common/typography';
import { Context } from '../../common/contextSelector/ContextSelector';

// Interfaces
import {
    IAnimalSummary,
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
    IFilteredAnimalList,
} from '../../interfaces/IAnimal';
import {
    AnimalListButtonsEnum,
    AnimalTypeEnum,
    AnnouncementTypeEnum,
    CarouselBoxTypeEnum,
    CommunicationSubjectEnum,
    DialogModeEnum,
    FarmingTypeEnum,
} from '../../interfaces/enums';
import { IAnimalAnnouncement, IUpdateAnnouncementResult } from '../../interfaces/IAnnouncement';
import { ITiltuApiResult } from '../../interfaces/IGeneral';
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import { IMainGroup } from '../../interfaces/IGroup';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import companyApi from '../../api/companyApi';
import animalsApi from '../../api/animalsApi';
import printingApi from '../../api/printingApi';

// Feature
import { ParamFilter } from './AnimalList';
import {
    isFarmDetailsValidForSalesTypeUpdate,
    mapAnimalsToAnimalAnnouncement,
    mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal,
    mapAnimalSummaryToAnnouncementDialog,
} from '../announcements/helpers/data';
import ManualAnnouncementDialog from '../manual-announcements/components/ManualAnnouncementDialog';
import BovineIntermediationAnnouncement from '../announcements/components/bovine-intermediation-announcement/BovineIntermediationAnnouncement';
import BovinePurchaseAnnouncement from '../announcements/components/bovine-purchase-announcement/BovinePurchaseAnnouncement';
import BovineSlaughterAnnouncement from '../announcements/components/bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import { mapAnnouncementResult } from '../announcements/components/bovine-slaughter-announcement/helpers/mapper';
import GroupDialog from '../Groups/components/GroupDialog';
import ModifySalesTypeDialog from '../announcements/components/sales-type/ModifySalesTypeDialog';
import { ISalesTypeDialogProps } from '../shared/types/propTypes';
import { ManualAnnouncementSelectionDialog } from '../manual-announcements/general/ManualAnnouncementSelectionDialog';
import {
    getBovineBreedString,
    getBovineSexString,
    getSalesTypeString,
    getUsageTypeString,
} from './AnimalListDataGridHelper';
import '../shared/styles/sharedStyles.scss';
import { ConfirmDeletionDialog } from '../../common/dialog';

// Feature - Feedback
import FeedbackDialog from '../../communication/feedback/FeedbackDialog';
import { FeedbackViewContext } from '../../communication/context/feedback/IFeedbackViewContext';

export interface AnimalListGridProps {
    allAnimalsData: IFilteredAnimalList[] | null;
    animalData: IAnimalSummaryExtraInfo[];
    animalGroups: IMainGroup[] | null;
    selectedParamFilter: ParamFilter | null;
    isLoading: boolean;
    handleAnimalAnnouncementMapToState: (data: any, type: AnnouncementTypeEnum) => void;
    mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) => void;
    farmAnnouncements: IAnimalAnnouncement[];
    farmData: IFarmDetails | null;
    hideAnimalData?: boolean;
    canCreateAnimalAnnouncement: boolean | null;
    removeAnimal: (animal: IFarmAnimal) => void;
}

interface AnimalListGridResources {
    BovineBreedParameters: ParameterValues;
    ACSucklerCowParameters: ParameterValues;
    BovineSexParameters: ParameterValues;
    BovineSalesTypeParameters: ParameterValues;
    BovineUsageParameters: ParameterValues;
    BovineProductionPlanCompleted: ParameterValues;
}

export default function AnimalListGrid(props: AnimalListGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);
    const { enqueueSnackbar } = useSnackbar();
    const dataGridApiRef = useGridApiRef();
    const context = useAppSelector((state) => state.context);

    const reclamationFeedbackBtnId = 'reclamation-feedback-btn';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resources, setResources] = useState<AnimalListGridResources | null>(null);
    const [selectedAnimalDataForAnnouncement, setSelectedAnimalDataForAnnouncement] =
        useState<IAnimalAnnouncement | null>(null);
    const [selectedAnimals, setSelectedAnimals] = useState<IAnimalSummary[]>([]);
    const [announcementDialogMode, setAnnouncementDialogMode] = useState<DialogModeEnum>(
        DialogModeEnum.Create
    );
    const [selectedAnnouncementType, setSelectedAnnouncementType] = useState<AnnouncementTypeEnum>(
        AnnouncementTypeEnum.Slaughter
    );
    const [announcementDialogVisible, setAnnouncementDialogVisible] = useState<boolean>(false);
    const [manualAnnouncementDialogVisible, setManualAnnouncementDialogVisible] =
        useState<boolean>(false);
    const [farmHasDifferingHoldingSiteId, setFarmHasDifferingHoldingSiteId] =
        useState<boolean>(false);

    const [groupDialogVisible, setGroupDialogVisible] = useState<boolean>(false);
    const [modifySalesTypeDialogVisible, setModifySalesTypeDialogVisible] =
        useState<boolean>(false);

    const [isManualDialogVisible, setIsManualDialogVisible] = useState<boolean>(false);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [animalGroups, setAnimalGroups] = useState<IMainGroup[]>(props.animalGroups || []);

    const [printingIsInProcess, setPrintingIsInProcess] = useState<boolean>(false);
    const [confirmAnimalRemovalDialogOpen, setConfirmAnimalRemovalDialogOpen] =
        useState<boolean>(false);
    const [animalToDelete, setAnimalToDelete] = useState<IAnimalSummaryExtraInfo | null>(null);
    const [feedbackDialogVisible, setFeedbackDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        setResources({
            BovineBreedParameters: paramRepository.resource('AnelmaBovine', 'BreedShort'),
            ACSucklerCowParameters: paramRepository.resource('AnelmaBovine', 'ACSucklerCow'),
            BovineSexParameters: paramRepository.resource('AnelmaBovine', 'BovineSex'),
            BovineSalesTypeParameters: paramRepository.resource('AnelmaBovine', 'SalesType'),
            BovineUsageParameters: paramRepository.resource('AnelmaBovine', 'UseMode'),
            BovineProductionPlanCompleted: paramRepository.resource(
                'AnelmaBovine',
                'ProductionPlanCompleted'
            ),
        });
    }, []);

    useEffect(() => {
        if (
            selectedAnimals.some(
                (value, idx, currentArray) => value.HoldingSiteId !== currentArray[0].HoldingSiteId
            ) &&
            selectedAnimals.length > 1
        ) {
            setFarmHasDifferingHoldingSiteId(true);
        } else {
            setFarmHasDifferingHoldingSiteId(false);
        }
    }, [selectedAnimals]);

    useEffect(() => {
        if (context.data.currentContext) {
            initializeFarmDetails(context.data.currentContext);
        } else setFarmDetails(undefined);
    }, [context]);

    useEffect(() => {
        if (props.canCreateAnimalAnnouncement !== null && !props.canCreateAnimalAnnouncement) {
            enqueueSnackbar(t('AnelmaBovine:1092'), {
                variant: 'warning',
            });
        }
    }, [props.canCreateAnimalAnnouncement]);

    // AN-2418, update groups state when groups prop changes otherwise state will be empty if user fiddles with the carousel boxes before groups GET is finished.
    useEffect(() => {
        if (isNotNil(props.animalGroups) && props.animalGroups.length > 0)
            setAnimalGroups(props.animalGroups);
    }, [props.animalGroups]);

    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => {
            setIsLoading(false);
        });
    };

    const isValidDate = (value: Date | string | undefined | null): boolean => {
        if (
            value === undefined ||
            value === null ||
            value === '' ||
            value === '0001-01-01T00:00:00' ||
            value === '1753-01-01T00:00:00'
        )
            return false;
        return true;
    };

    const getFormattedDate = (value: Date | string): string => {
        return moment(value).format(dayFormat);
    };

    const getEarNumber = (value: string | undefined): string => {
        if (!value || value === '-1') return '';
        const zerosToAdd = 4 - value.length;

        let zeros = '';
        for (let i = 0; i < zerosToAdd; i++) {
            zeros += '0';
        }

        return zeros.concat(value);
    };

    const getAnnoucementColumnValue = (announcement: IAnimalAnnouncement): string => {
        const announcementText =
            announcement.Type === AnnouncementTypeEnum.Slaughter ||
            announcement.Type === AnnouncementTypeEnum.ManualBovineSlaughter
                ? t('AnelmaBovine:1173')
                : announcement.Type === AnnouncementTypeEnum.Intermediation ||
                  announcement.Type === AnnouncementTypeEnum.ManualBovineIntermediation
                ? t('AnelmaBovine:1174')
                : '#';
        return `${announcementText} ${getFormattedDate(announcement.Created)}`;
    };

    const getAnimaGroupColumnValue = (mainGroup: IMainGroup, animalId: string): string => {
        const childGroup = mainGroup.ChildGroups.find((cg) =>
            cg.Animals.find((a) => a.AnimalGUID === animalId)
        );
        return `${mainGroup.Name}, ${childGroup?.Name}`;
    };

    const printAnimals = async () => {
        setPrintingIsInProcess(true);
        var selectedParameter = '';

        if (props.selectedParamFilter && props.selectedParamFilter?.paramCode)
            // paramCode - 1 because CarouselBoxTypeEnum is 1 bigger than BE AnimalFilterQueryType
            selectedParameter = String(props.selectedParamFilter?.paramCode - 1);

        var farmName =
            props.farmData?.Names && props.farmData.Names.length > 0
                ? props.farmData.Names[0].Value
                : '';
        var farmId = props.farmData?.Id ? props.farmData.Id : '';
        var animals = gridFilteredSortedRowEntriesSelector(dataGridApiRef).map(
            (a) => a.model as IAnimalSummaryExtraInfo
        );

        await printingApi
            .printAnimals(
                selectedParameter,
                farmName,
                farmId,
                animals,
                props.farmAnnouncements,
                animalGroups
            )
            .then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                }

                setPrintingIsInProcess(false);
            });
    };

    const preColumns: AGridColumns = [
        {
            field: 'EuIdentifier',
            headerName: t('AnelmaBovine:1005'),
            renderCell: (params) => {
                const value = (params.row as IAnimalSummary)?.EuIdentifier;

                let formattedEU = Utils.formatEuIdentifier(value);
                if (formattedEU.indexOf('-')) {
                    const splittedValue = formattedEU.split('-');
                    const start = splittedValue[0].length - 4;
                    const end = splittedValue[0].length;

                    const startStr = formattedEU.substring(0, start);
                    const endStr = formattedEU.substring(start, end);

                    return (
                        <TextWithTooltip
                            text={`${startStr}${endStr}-${splittedValue[1]}`}
                        ></TextWithTooltip>
                    );
                }

                return formattedEU;
            },
            type: 'string',
            valueGetter: (params, row) => {
                return (row as IAnimalSummary)?.EuIdentifier ?? '';
            },
            width: 160,
        },
        {
            field: 'EarNumber',
            headerName: t('AnelmaBovine:1007'),
            type: 'string',
            renderCell: (params) => getEarNumber(params.value?.toString()),
            width: 60,
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1009'),
            type: 'string',
            width: 60,
            renderCell: (params) => {
                const value = (params.row as IAnimalSummaryExtraInfo)?.Sex;
                const bovineSex = resources
                    ? (resources.BovineSexParameters.find((p) => value === Number(p.code))
                          ?.text as string)
                    : '';
                let warningIconTooltip = null;
                const breedingContractAnimalDetailsIndex =
                    (params.row as IAnimalSummaryExtraInfo)?.AlternativeAnimalDetails.length > 0 // BC only atm
                        ? 0
                        : -1;

                if (breedingContractAnimalDetailsIndex != -1 && resources) {
                    const breedingContractValue = (params.row as IAnimalSummaryExtraInfo)
                        .AlternativeAnimalDetails[breedingContractAnimalDetailsIndex].Sex;
                    const bovineSexFromBreedingContract = resources.BovineSexParameters.find(
                        (p) => breedingContractValue === Number(p.code)
                    )?.text as string;

                    if (value != breedingContractValue)
                        warningIconTooltip = t('AnelmaBovine:1088')
                            .replace('{BovineSexBreedingContracts}', bovineSexFromBreedingContract)
                            .replace('{BovineSexCattleRegister}', bovineSex);
                }

                return (
                    <>
                        <Typography
                            noWrap
                            variant='body2'
                            data-robot-id={'animal-list-grid-typograhty-gender'}
                        >
                            {bovineSex}
                            {warningIconTooltip && (
                                <Tooltip title={warningIconTooltip}>
                                    <PriorityHigh style={{ color: '#d32f2f' }} />
                                </Tooltip>
                            )}
                        </Typography>
                    </>
                );
            },
            valueGetter: (params, row) => {
                return getBovineSexString(
                    row as IAnimalSummaryExtraInfo,
                    resources?.BovineSexParameters
                );
            },
        },
        {
            field: 'BirthDate',
            headerName: t('AnelmaBovine:1011'),
            type: 'date',
            width: 90,
            renderCell: (params) => {
                const value = (params.row as IAnimalSummaryExtraInfo)?.BirthDate;
                const bovineBirthDate = isValidDate(value) ? getFormattedDate(value) : '';
                let warningIconTooltip = null;
                const breedingContractAnimalDetailsIndex =
                    (params.row as IAnimalSummaryExtraInfo)?.AlternativeAnimalDetails.length > 0
                        ? 0
                        : -1;

                if (breedingContractAnimalDetailsIndex != -1) {
                    const breedingContractValue = (params.row as IAnimalSummaryExtraInfo)
                        .AlternativeAnimalDetails[breedingContractAnimalDetailsIndex].BirthDate;
                    const bovineBirthDateFromBreedingContract = isValidDate(breedingContractValue)
                        ? getFormattedDate(breedingContractValue)
                        : '';

                    if (value != breedingContractValue)
                        warningIconTooltip = t('AnelmaBovine:1090')
                            .replace(
                                '{BirthDateBreedingContracts}',
                                bovineBirthDateFromBreedingContract
                            )
                            .replace('{BirthDateCattleRegister}', bovineBirthDate);
                }

                return (
                    <>
                        <TextWithTooltip
                            data-robot-id={'animal-list-typography-bovine-birthdate'}
                            text={bovineBirthDate}
                        />
                        {warningIconTooltip && (
                            <Tooltip title={warningIconTooltip}>
                                <PriorityHigh style={{ color: '#d32f2f' }} />
                            </Tooltip>
                        )}
                    </>
                );
            },
            valueGetter: (params) => new Date(params),
        },
        {
            field: 'AgeMonths',
            headerName: t('AnelmaBovine:1012'),
            type: 'number',
            renderCell: (params) => {
                return isNotNil(params.value)
                    ? (Math.round(Number(params.value) * 10) / 10).toFixed(1)
                    : '';
            },
            width: 70,
        },
        {
            field: 'MatureForSlaughterDate',
            headerName: t('AnelmaBovine:1094'),
            type: 'string',
            renderCell: (params) => (
                <TextWithTooltip
                    text={getComplexSlaughterDate((params.value as string)?.split('/'))}
                />
            ),
            filterable: false,
            width: 80,
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaBovine:1015'),
            renderCell: (params) => {
                return <TextWithTooltip text={(params.value as string) ?? ''} />;
            },
            valueGetter: (params, row) => {
                return getSalesTypeString(
                    row as IAnimalSummaryExtraInfo,
                    resources?.BovineSalesTypeParameters
                );
            },
            type: 'string',
            width: 80,
        },
        {
            field: 'UsageType',
            headerName: t('AnelmaBovine:1087'),
            type: 'string',
            width: 67,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value?.toString() ?? ''} />;
            },
            valueGetter: (params, row) => {
                return getUsageTypeString(
                    row as IAnimalSummaryExtraInfo,
                    resources?.BovineUsageParameters
                );
            },
        },
        props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.SaleAnnouncement
            ? {
                  field: 'AnimalAnnouncement',
                  headerName: t('AnelmaBovine:1141'),
                  type: 'string',
                  renderCell: (params) => {
                      return <TextWithTooltip text={(params.value as string) ?? ''} />;
                  },
                  valueGetter: (params, row) => {
                      const animal = row as IAnimalSummaryExtraInfo;

                      const foundInAnnouncements = props.farmAnnouncements.filter((a) =>
                          a.Batches.find((ab) =>
                              ab.Animals.find((abb) => abb.AnimalId === animal?.Id)
                          )
                      );
                      let foundInAnnouncement = null;

                      if (foundInAnnouncements.length > 0) {
                          foundInAnnouncements.sort(
                              (a, b) => Date.parse(b.Created) - Date.parse(a.Created)
                          );
                          foundInAnnouncement = foundInAnnouncements[0];
                      }

                      return foundInAnnouncement
                          ? getAnnoucementColumnValue(foundInAnnouncement)
                          : '';
                  },
                  width: 80,
              }
            : {
                  field: 'remove',
              },
        {
            field: 'Name',
            headerName: t('AnelmaBovine:1008'),
            type: 'string',
            width: 60,
            valueGetter: (params, row) => {
                const value = (row as IAnimalSummary)?.Name;
                return value ? (value.length > 50 ? value.substring(0, 49) : value) : '';
            },
            renderCell: (params) => {
                return <TextWithTooltip text={params.value?.toString() ?? ''} />;
            },
        },
        {
            field: 'DateOfPurchase',
            headerName: t('AnelmaBovine:1013'),
            type: 'date',
            width: 90,
            renderCell: (params) => {
                const value = (params.row as IAnimalSummaryExtraInfo)?.DateOfPurchase;
                const bovinePurchaceDate = isValidDate(value) ? getFormattedDate(value) : '';
                let warningIconTooltip = null;
                const breedingContractAnimalDetailsIndex =
                    (params.row as IAnimalSummaryExtraInfo)?.AlternativeAnimalDetails.length > 0
                        ? 0
                        : -1;

                if (breedingContractAnimalDetailsIndex != -1) {
                    const breedingContractValue = (params.row as IAnimalSummaryExtraInfo)
                        .AlternativeAnimalDetails[breedingContractAnimalDetailsIndex].PurchaceDate;
                    const bovinePurchaceDateFromBreedingContract = isValidDate(
                        breedingContractValue
                    )
                        ? getFormattedDate(breedingContractValue)
                        : '';

                    if (value != breedingContractValue)
                        warningIconTooltip = t('AnelmaBovine:1091')
                            .replace(
                                '{ArrivalDateBreedingContracts}',
                                bovinePurchaceDateFromBreedingContract
                            )
                            .replace('{ArrivalDateCattleRegister}', bovinePurchaceDate);
                }

                return (
                    <>
                        <TextWithTooltip
                            data-robot-id={'animal-list-typography-bovine-purchace-date'}
                            text={bovinePurchaceDate}
                        />
                        {warningIconTooltip && (
                            <Tooltip title={warningIconTooltip}>
                                <PriorityHigh style={{ color: '#d32f2f' }} />
                            </Tooltip>
                        )}
                    </>
                );
            },
            valueGetter: (params) => new Date(params),
        },
        {
            field: 'AnimalGroup',
            headerName: t('AnelmaBovine:1142'),
            renderCell: (params) => {
                return <TextWithTooltip text={(params.value as string) ?? ''} />;
            },
            valueGetter: (params, row) => {
                const animal = row as IAnimalSummaryExtraInfo;

                const foundInGroup = animalGroups?.find((mg) =>
                    mg.ChildGroups.find((ch) =>
                        ch.Animals.find((cha) => cha.AnimalGUID === animal?.Id)
                    )
                );

                return foundInGroup ? getAnimaGroupColumnValue(foundInGroup, animal.Id) : '';
            },
            width: 90,
        },
        {
            field: 'Breed',
            headerName: t('AnelmaBovine:1010'),
            type: 'string',
            width: 20,
            renderCell: (params) => {
                const value = (params.row as IAnimalSummaryExtraInfo)?.Breed;
                const bovineBreed = resources
                    ? (resources.BovineBreedParameters.find((p) => value === Number(p.code))
                          ?.text as string)
                    : '';
                let warningIconTooltip = null;
                const breedingContractAnimalDetailsIndex =
                    (params.row as IAnimalSummaryExtraInfo)?.AlternativeAnimalDetails.length > 0
                        ? 0
                        : -1;

                if (breedingContractAnimalDetailsIndex != -1 && resources) {
                    const breedingContractValue = (params.row as IAnimalSummaryExtraInfo)
                        .AlternativeAnimalDetails[breedingContractAnimalDetailsIndex].Breed;
                    const bovineBreedFromBreedingContract = resources.BovineBreedParameters.find(
                        (p) => breedingContractValue === Number(p.code)
                    )?.text as string;

                    if (value != breedingContractValue)
                        warningIconTooltip = t('AnelmaBovine:1089')
                            .replace(
                                '{BovineBreedBreedingContracts}',
                                bovineBreedFromBreedingContract
                            )
                            .replace('{BovineBreedCattleRegister}', bovineBreed);
                }

                return (
                    <>
                        <Typography
                            noWrap
                            variant='body2'
                            data-robot-id={'animal-list-typography-bovine-breed'}
                        >
                            {bovineBreed}
                        </Typography>
                        {warningIconTooltip && (
                            <Tooltip title={warningIconTooltip}>
                                <PriorityHigh style={{ color: '#d32f2f' }} />
                            </Tooltip>
                        )}
                    </>
                );
            },
            valueGetter: (params, row) => {
                return getBovineBreedString(
                    row as IAnimalSummaryExtraInfo,
                    resources?.BovineBreedParameters
                );
            },
        },
        {
            field: 'HoldingSiteId',
            headerName: t('AnelmaBovine:1063'),
            type: 'string',
            width: 125,
            renderCell: (params) => (
                <TextWithTooltip text={isNotNil(params.value) ? params.value : ''} />
            ),
        },
        {
            field: 'TransmissionAgeDate',
            headerName: t('AnelmaBovine:1095'),
            type: 'string',
            renderCell: (params) => (
                <TextWithTooltip
                    text={getComplexSlaughterDate((params.value as string)?.split('/'))}
                />
            ),

            filterable: false,
            width: 80,
        },
    ];

    const getComplexSlaughterDate = (value: string[]) => {
        if (value && value.length > 0) {
            let targetDate = moment().year(Number(value[1])).week(Number(value[0]));

            return moment(targetDate).format('WW/YYYY');
        }

        return '';
    };

    const columns = preColumns.filter((c) => c.field !== 'remove');

    const dataRows = props.animalData.map((i) => mergeRight(i, { id: i.Id }));

    const handleAnnouncementDialogOpening = (
        dialogType: AnnouncementTypeEnum,
        dialogMode: DialogModeEnum,
        isManualAnnouncement?: boolean
    ) => {
        // Latest modifications: http://jira.mtech.fi/browse/AN-1860
        if (
            (dialogType === AnnouncementTypeEnum.Slaughter ||
                dialogType === AnnouncementTypeEnum.Intermediation) &&
            farmHasDifferingHoldingSiteId
        ) {
            enqueueSnackbar(t('AnelmaBovine:1064'), {
                variant: 'error',
            });
            return;
        }
        setSelectedAnnouncementType(dialogType);
        setAnnouncementDialogMode(dialogMode);
        isManualAnnouncement === undefined && !isManualAnnouncement
            ? setAnnouncementDialogVisible(true)
            : setManualAnnouncementDialogVisible(true);
    };

    const handleAnnouncementDialogClosing = (isManualAnnouncement?: boolean) => {
        isManualAnnouncement === undefined && !isManualAnnouncement
            ? setAnnouncementDialogVisible(false)
            : setManualAnnouncementDialogVisible(false);
    };

    const handleAnnouncementMap = (
        data: ITiltuApiResult<IUpdateAnnouncementResult>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => {
        // Used to prevent dialog from closing before chain information is set.
        if (type === AnnouncementTypeEnum.SlaughterWithChainInformation)
            type = AnnouncementTypeEnum.Slaughter;
        else setAnnouncementDialogVisible(false);

        props.handleAnimalAnnouncementMapToState(
            mapAnnouncementResult(data.Entity, holdingSiteId, type),
            type
        );
    };

    const handleUpdatesSalesTypeToSelectedAnimalsData = (data: IFarmAnimal[]) => {
        props.mapUpdatedSalesTypeToAllAnimalsData(data);
        if (selectedAnimals) {
            let currentAnimalsData = Array.from(selectedAnimals);
            for (const changedAnimal of data) {
                for (const currentAnimal of currentAnimalsData) {
                    if (changedAnimal.AnimalGUID === currentAnimal.Id) {
                        if (changedAnimal.SalesType != 0)
                            currentAnimal.SalesType = changedAnimal.SalesType;
                    }
                }
            }
            setSelectedAnimals(currentAnimalsData);
            dataGridApiRef.current.setRowSelectionModel([]); // deselect data grid rows with the exposed apiRef from x data grid/data grid
        }
    };

    const salesTypeDialogProps: ISalesTypeDialogProps = {
        id: '',
        onClose: () => setModifySalesTypeDialogVisible(false),
        animals: mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal(selectedAnimals),
        allAnimals: null,
        handleSalesTypeUpdate: handleUpdatesSalesTypeToSelectedAnimalsData,
        farmId: isFarmDetailsValidForSalesTypeUpdate(props.farmData),
        farmDetails: props.farmData ?? undefined,
        mode: DialogModeEnum.Create,
        isFromAnimalList: true,
    };

    const isButtonVisible = (button: AnimalListButtonsEnum): boolean => {
        switch (button) {
            case AnimalListButtonsEnum.Groups:
                return props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder;
            case AnimalListButtonsEnum.NewSlaughterAnnouncement:
                return (
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.WaitingStatus &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.SaleAnnouncement &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder
                );
            case AnimalListButtonsEnum.NewIntermediationAnnouncement:
                return (
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.WaitingStatus &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.Cow &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.SaleAnnouncement &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder
                );
            case AnimalListButtonsEnum.NewPurchaseAnnouncement:
                return props.selectedParamFilter?.paramCode === CarouselBoxTypeEnum.CalfOrder;
            case AnimalListButtonsEnum.SalesType:
            case AnimalListButtonsEnum.ReclamationFeedback:
                return (
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.SaleAnnouncement &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder
                );
            case AnimalListButtonsEnum.BovineRegisterLink:
            case AnimalListButtonsEnum.CarcassNet:
                return props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder;
            case AnimalListButtonsEnum.ManualAnnouncements:
                return (
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.WaitingStatus &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.SaleAnnouncement &&
                    props.selectedParamFilter?.paramCode !== CarouselBoxTypeEnum.CalfOrder
                );
            default:
                return false;
        }
    };

    const getActions: () => ADataGridActions = () => {
        // auth.canViewAdminContextSelector is same as isAdmin
        return auth.canViewAdminContextSelector
            ? [(params) => getRemoveAnimalAction(params.row as unknown as IAnimalSummaryExtraInfo)]
            : [];
    };

    const getRemoveAnimalAction = (animal: IAnimalSummaryExtraInfo) => {
        let animalCanBeRemovedFlag = 4;
        return (
            <ADeleteIconButton
                key={`${animal.Id}-view`}
                onClick={() => deleteAnimal(animal)}
                disabled={(animal.Flags & animalCanBeRemovedFlag) == 0}
            />
        );
    };

    const deleteAnimal = (animal: IAnimalSummaryExtraInfo) => {
        setAnimalToDelete(animal);
        setConfirmAnimalRemovalDialogOpen(true);
    };

    const deleteAnimalConfirmationDialogText = () =>
        t('AnelmaBovine:1175').replace('{EUIdentifier}', animalToDelete?.EuIdentifier ?? '');

    const invalidateAndRemoveAnimalFromList = () => {
        if (!animalToDelete) return;

        animalsApi.invalidateTilTuAnimal(animalToDelete).then((response) => {
            if (!response || !response.Entity)
                enqueueSnackbar(t('AnelmaGeneral:1145'), {
                    variant: 'error',
                });
            else {
                let animalToRemove = response.Entity;
                if (!animalToRemove.ValidTo) return;

                props.removeAnimal(animalToRemove);
                enqueueSnackbar(t('AnelmaGeneral:1018'), {
                    variant: 'success',
                });
            }
        });
    };

    const isCreateAnimalAnnouncementBtnDisabled = (): boolean => {
        if (props.canCreateAnimalAnnouncement) return false;
        else if (!props.canCreateAnimalAnnouncement) return true;
        return true;
    };

    /** Against farming types deduce if reclamation feedback btn should be visible in certain view. The view's visibility is passed within the 'shouldBeVisibleInView' variable.
     *  The rulings are from 'Anelma Nauta Eläinlista.docx' suunnittelu document.
     * @param shouldBeVisibleInView boolean
     * @returns
     */
    const isReclamationBtnVisible = (shouldBeVisibleInView: boolean): boolean => {
        const farmProductionLines = farmDetails?.ProductionLines.flatMap((_) => {
            if (_.Type === AnimalTypeEnum.Bovine) return _.FarmingTypes;
            return [];
        });

        if (shouldBeVisibleInView && isNotNil(farmProductionLines)) {
            return farmProductionLines.some(
                (_) =>
                    _.valueOf() === FarmingTypeEnum.Upbringing ||
                    _.valueOf() === FarmingTypeEnum.Transmission
            );
        }

        return false;
    };

    return (
        <>
            {props.selectedParamFilter !== null ? (
                <Container id={'anelma-animals-animal-list-grid-container'}>
                    {props.hideAnimalData != true && (
                        <Grid container>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: 'right',
                                        paddingRight: 40,
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                        minHeight: 76,
                                    }}
                                >
                                    {t('AnelmaBovine:1003').replace(
                                        '{count}',
                                        !dataRows ? '0' : dataRows.length.toString()
                                    )}

                                    {printingIsInProcess ? (
                                        <CircularProgress
                                            style={{
                                                width: 24,
                                                height: 24,
                                                marginLeft: 22,
                                            }}
                                        />
                                    ) : (
                                        <ADefaultIconButton
                                            style={{ marginLeft: 10 }}
                                            icon={<PictureAsPdf />}
                                            onClick={printAnimals}
                                            tooltip=''
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {props.selectedParamFilter !== null ? (
                        <>
                            {props.hideAnimalData != true && (
                                <ADataGrid
                                    data-robot-id={'animal-list-data-grid'}
                                    disableSelectionOnClick={true}
                                    actions={getActions()}
                                    columns={columns}
                                    enableFiltering
                                    enablePagination
                                    enableRowNumbers
                                    loading={props.isLoading}
                                    rows={!dataRows ? [] : dataRows}
                                    onSelectionModelChange={(selectionModel) => {
                                        setSelectedAnimalDataForAnnouncement(
                                            mapAnimalsToAnimalAnnouncement(
                                                mapAnimalSummaryToAnnouncementDialog(
                                                    props.animalData,
                                                    selectionModel
                                                )
                                            )
                                        );
                                        setSelectedAnimals(
                                            mapAnimalSummaryToAnnouncementDialog(
                                                props.animalData,
                                                selectionModel
                                            )
                                        );
                                    }}
                                    apiRef={dataGridApiRef}
                                />
                            )}

                            <Grid
                                container
                                direction='row'
                                className='animal-announcement-grid-floating-bottom'
                            >
                                <Grid container data-robot-id={'animal-list-row-container'}>
                                    {isButtonVisible(AnimalListButtonsEnum.Groups) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                        >
                                            <ADefaultButton
                                                onClick={() => setGroupDialogVisible(true)}
                                                children={t('AnelmaBovine:1101')}
                                                disabled={!auth.canViewGrouping}
                                            ></ADefaultButton>
                                        </Grid>
                                    )}

                                    {isButtonVisible(
                                        AnimalListButtonsEnum.NewSlaughterAnnouncement
                                    ) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                            data-robot-id={'new-slaughter-announcement'}
                                        >
                                            <ADefaultButton
                                                onClick={() =>
                                                    handleAnnouncementDialogOpening(
                                                        AnnouncementTypeEnum.Slaughter,
                                                        DialogModeEnum.Create
                                                    )
                                                }
                                                children={t('AnelmaBovine:1020')}
                                                disabled={isCreateAnimalAnnouncementBtnDisabled()}
                                            ></ADefaultButton>
                                        </Grid>
                                    )}

                                    {isButtonVisible(
                                        AnimalListButtonsEnum.NewIntermediationAnnouncement
                                    ) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                            data-robot-id={'new-intermediation-announcement'}
                                        >
                                            <ADefaultButton
                                                onClick={() =>
                                                    handleAnnouncementDialogOpening(
                                                        AnnouncementTypeEnum.Intermediation,
                                                        DialogModeEnum.Create
                                                    )
                                                }
                                                children={t('AnelmaBovine:1021')}
                                                disabled={isCreateAnimalAnnouncementBtnDisabled()}
                                            ></ADefaultButton>
                                        </Grid>
                                    )}

                                    {isButtonVisible(AnimalListButtonsEnum.SalesType) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                        >
                                            <ADefaultButton
                                                style={{ marginLeft: '5px' }}
                                                onClick={() =>
                                                    setModifySalesTypeDialogVisible(true)
                                                }
                                                children={t('AnelmaBovine:1019')}
                                                disabled={selectedAnimals.length === 0}
                                            ></ADefaultButton>
                                        </Grid>
                                    )}

                                    {isButtonVisible(
                                        AnimalListButtonsEnum.NewPurchaseAnnouncement
                                    ) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                            data-robot-id={'new-puchase-announcement'}
                                        >
                                            {auth.canCreateAnelmaBovineBrokerPurchase && (
                                                <ADefaultButton
                                                    onClick={() =>
                                                        handleAnnouncementDialogOpening(
                                                            AnnouncementTypeEnum.BovinePurchase,
                                                            DialogModeEnum.Create
                                                        )
                                                    }
                                                    children={t('AnelmaBovine:1058')}
                                                    disabled={isCreateAnimalAnnouncementBtnDisabled()}
                                                ></ADefaultButton>
                                            )}
                                        </Grid>
                                    )}

                                    {isButtonVisible(AnimalListButtonsEnum.BovineRegisterLink) && (
                                        <Grid
                                            data-robot-id={'bovine-register-link'}
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            sx={{
                                                fontSize: '0.8em',
                                                padding: '5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <a
                                                href={t('AnelmaBovine:1075')}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                {t('AnelmaBovine:1025')}
                                            </a>
                                        </Grid>
                                    )}

                                    {isButtonVisible(AnimalListButtonsEnum.CarcassNet) && (
                                        <Grid
                                            data-robot-id={'carcass-net'}
                                            sx={{
                                                fontSize: '0.8em',
                                                padding: '5px',
                                                textAlign: 'center',
                                            }}
                                            item
                                        >
                                            <a
                                                href={t('AnelmaBovine:1076')}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                {t('AnelmaBovine:1026')}
                                            </a>
                                        </Grid>
                                    )}

                                    {isButtonVisible(AnimalListButtonsEnum.ManualAnnouncements) && (
                                        <Grid
                                            className='animal-announcement-button-content-position'
                                            item
                                            data-robot-id={'manual-announcement'}
                                        >
                                            <ADefaultButton
                                                onClick={() => {
                                                    setIsManualDialogVisible(true);
                                                }}
                                                children={t('AnelmaBovine:1135')}
                                                disabled={isCreateAnimalAnnouncementBtnDisabled()}
                                            ></ADefaultButton>
                                        </Grid>
                                    )}

                                    {isReclamationBtnVisible(
                                        isButtonVisible(AnimalListButtonsEnum.ReclamationFeedback)
                                    ) && (
                                        <Grid
                                            item
                                            id={reclamationFeedbackBtnId}
                                            data-robot-id={reclamationFeedbackBtnId}
                                            className='animal-announcement-button-content-position'
                                        >
                                            <ADefaultButton
                                                onClick={() => setFeedbackDialogVisible(true)}
                                                children={t('AnelmaBovine:1129')}
                                                disabled={!auth.canCreateFeedback}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            {announcementDialogVisible &&
                            selectedAnnouncementType === AnnouncementTypeEnum.Slaughter ? (
                                <BovineSlaughterAnnouncement
                                    data-robot-id={'bovine-slaughter-announcement'}
                                    data={null}
                                    onClose={() => handleAnnouncementDialogClosing()}
                                    mode={announcementDialogMode}
                                    selectedAnimals={selectedAnimals}
                                    selectedHoldingSites={selectedAnimals
                                        .slice(0, 1)
                                        .map((_) => _.HoldingSiteId)}
                                    animals={props.animalData}
                                    processAnnouncementSave={handleAnnouncementMap}
                                    mapUpdatedSalesTypeToAllAnimalsData={(data) =>
                                        handleUpdatesSalesTypeToSelectedAnimalsData(data)
                                    }
                                    loading={false}
                                />
                            ) : announcementDialogVisible &&
                              selectedAnnouncementType === AnnouncementTypeEnum.Intermediation ? (
                                <BovineIntermediationAnnouncement
                                    data-robot-id={'bovine-intermediation-announcement'}
                                    data={null}
                                    onClose={() => handleAnnouncementDialogClosing()}
                                    mode={announcementDialogMode}
                                    selectedAnimals={selectedAnimals}
                                    selectedHoldingSites={selectedAnimals
                                        .slice(0, 1)
                                        .map((_) => _.HoldingSiteId)}
                                    animals={props.animalData}
                                    processAnnouncementSave={handleAnnouncementMap}
                                    mapUpdatedSalesTypeToAllAnimalsData={(data) =>
                                        handleUpdatesSalesTypeToSelectedAnimalsData(data)
                                    }
                                    loading={false}
                                />
                            ) : announcementDialogVisible &&
                              selectedAnnouncementType === AnnouncementTypeEnum.BovinePurchase ? (
                                <BovinePurchaseAnnouncement
                                    data-robot-id={'bovine-purchase-announcement'}
                                    data={null}
                                    onClose={() => handleAnnouncementDialogClosing()}
                                    mode={announcementDialogMode}
                                    processAnnouncementSave={handleAnnouncementMap}
                                />
                            ) : null}

                            {isManualDialogVisible && (
                                <ManualAnnouncementSelectionDialog
                                    data-robot-id={'manual-announcement-selection-dialog'}
                                    onClose={() => setIsManualDialogVisible(false)}
                                    onManualAnnouncementSelect={(type: AnnouncementTypeEnum) => {
                                        setIsManualDialogVisible(false);
                                        handleAnnouncementDialogOpening(
                                            type,
                                            DialogModeEnum.Create,
                                            true
                                        );
                                    }}
                                />
                            )}
                            {feedbackDialogVisible && (
                                <FeedbackViewContext.Provider
                                    value={{
                                        farmDetails: isNotNil(farmDetails) ? farmDetails : null,
                                        preSelectedBovines: selectedAnimals,
                                        preSelectedSubject:
                                            CommunicationSubjectEnum.CompensationRequest,
                                        feedbackSummaries: [],
                                        setToSummaries: (_) => {},
                                        feedbackViewLoading: false,
                                        updateToSummaries: (_) => {},
                                    }}
                                >
                                    <FeedbackDialog
                                        onClose={() => setFeedbackDialogVisible(false)}
                                        formMode={'create'}
                                    />
                                </FeedbackViewContext.Provider>
                            )}

                            {manualAnnouncementDialogVisible && (
                                <ManualAnnouncementDialog
                                    data-robot-id={'manual-announcement-dialog'}
                                    announcementData={null}
                                    announcementType={selectedAnnouncementType}
                                    mode={announcementDialogMode}
                                    onClose={() => handleAnnouncementDialogClosing(true)}
                                    processAnnouncementSave={handleAnnouncementMap}
                                    mapUpdatedSalesTypeToAllAnimalsData={(data) =>
                                        handleUpdatesSalesTypeToSelectedAnimalsData(data)
                                    }
                                    animals={props.animalData}
                                    withChainInformation={true}
                                />
                            )}

                            {groupDialogVisible && (
                                <GroupDialog
                                    data-robot-id={'group-dialog'}
                                    onClose={() => setGroupDialogVisible(false)}
                                    onDataChange={(maingroups: IMainGroup[]) => {
                                        setAnimalGroups(maingroups);
                                    }}
                                    allAnimals={props.allAnimalsData}
                                    animals={props.animalData}
                                    selectedAnimals={selectedAnimals}
                                    farmGuid={farmDetails?.Id as string}
                                />
                            )}

                            {modifySalesTypeDialogVisible && (
                                <ModifySalesTypeDialog
                                    {...salesTypeDialogProps}
                                    data-robot-id={'modify-salestype-dialog'}
                                />
                            )}

                            {confirmAnimalRemovalDialogOpen && (
                                <ConfirmDeletionDialog
                                    data-robot-id={'confirm-animal-removal-dialog'}
                                    confirmation={deleteAnimalConfirmationDialogText()}
                                    onClose={() => {
                                        setConfirmAnimalRemovalDialogOpen(
                                            !confirmAnimalRemovalDialogOpen
                                        );
                                        setAnimalToDelete(null);
                                    }}
                                    onDelete={() => {
                                        invalidateAndRemoveAnimalFromList();
                                        setConfirmAnimalRemovalDialogOpen(
                                            !confirmAnimalRemovalDialogOpen
                                        );
                                        setAnimalToDelete(null);
                                    }}
                                    open
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Container>
            ) : (
                <Grid container style={{ paddingTop: 100, paddingBottom: 100 }}>
                    <Typography variant='h6' data-robot-id={'animal-list-typography'}>
                        {t('AnelmaBovine:1002')}
                    </Typography>
                </Grid>
            )}
        </>
    );
}
