// Libraries
import styled from '@emotion/styled';
import moment from 'moment';

// MUI
// Core
import utils from '../../core/utils';

// Common
// Interfaces
// Store
// API
// Messaging
// Feature - Notifications

const StyledDateDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '8px',
}));

interface INotificationCreatedDateProps {
    createdDate: string;
}

export default function NotificationCreatedDate({ createdDate }: INotificationCreatedDateProps) {
    const getDate = (date: string) => {
        const currentMoment = moment().utc();
        const targetMoment = utils.date.utcStringToLocalMoment(date) as moment.Moment;

        const hoursPassed = moment.duration(currentMoment.diff(targetMoment)).asHours();

        return hoursPassed <= 24
            ? getHours(currentMoment, targetMoment)
            : targetMoment.utc().format('DD.MM.YYYY');
    };

    const getHours = (current: moment.Moment, target: moment.Moment) => {
        // Moment formats: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
        const hours = moment
            .utc(moment(current, 'DD/MM/YYYY HH:mm:ss').diff(moment(target, 'DD/MM/YYYY HH:mm:ss')))
            .format('H');

        return hours === '0' ? '1 h' : `${hours} h`;
    };

    return <StyledDateDiv>{getDate(createdDate)}</StyledDateDiv>;
}
