// Libraries
import { useContext } from 'react';
import { isNotNil } from 'ramda';

// MUI
import { Box, Grid, Paper, styled } from '@mui/material';

// Core
import auth from '../../core/authorization';

// Common

// Interfaces
import { INotificationResult, NotificationTypeEnum } from '../../interfaces/INotification';

// Store
// API
// Feature - Notifications
import NotificationIcon from './NotificationIcon';
import NotificationContent from './NotificationContent';
import NotificationDelete from './NotificationDelete';
import NotificationCreatedDate from './NotificationCreatedDate';
import { NotificationViewContext } from '../context/INotificationContext';

const StyledNotificationBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '290px',
    height: '60px',
    marginTop: '10px',
}));

const StyledContentGrid = styled(Grid)(({ theme }) => ({
    fontSize: 'small',
    fontWeight: 600,
    overflowWrap: 'break-word',
    height: '60px',
}));

export default function NotificationBox() {
    const { notifications } = useContext(NotificationViewContext);

    const applyIsReadStyling = (isRead?: string) => {
        return isNotNil(isRead)
            ? { width: '290px', height: '60px' }
            : { width: '290px', height: '60px', backgroundColor: '#e8f1ef' };
    };

    /** Sort by date but prod. plan notifications are always first no matter what.
     *
     * More info on custom comparators: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
     *
     * @param a INotificationResult
     * @param b INotificationResult
     */
    const sortNotifications = (a: INotificationResult, b: INotificationResult) => {
        if (a.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear) return -1;
        else if (b.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear) return 1;
        else return new Date(b.Created).valueOf() - new Date(a.Created).valueOf();
    };

    return (
        <>
            {notifications.sort(sortNotifications).map((_) => {
                return (
                    <StyledNotificationBox key={_.NotificationId}>
                        <Paper elevation={1} sx={{ width: 'inherit' }}>
                            <Grid container sx={applyIsReadStyling(_.ReadDateTime)}>
                                <NotificationIcon type={_.TypeId} />

                                <StyledContentGrid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    key={_.NotificationId}
                                >
                                    <NotificationContent notification={_} />
                                    <NotificationCreatedDate createdDate={_.Created} />
                                </StyledContentGrid>

                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {auth.canDeleteNotifications && (
                                        <NotificationDelete notification={_} />
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </StyledNotificationBox>
                );
            })}
        </>
    );
}
