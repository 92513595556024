// Libraries
import { createContext } from 'react';

// MUI
// Core
// Common
// Interfaces
import { INotificationResult } from '../../interfaces/INotification';

// Store
// API
// Feature

interface INotificationViewContext {
    notifications: INotificationResult[];
    setNotifications: (_: INotificationResult[]) => void;
    setRead: (data: INotificationResult[]) => void;
    deleteNotification: (data: INotificationResult) => void;
    deleteNotifications: () => void;
    /** Is used to set anchor on NotificationView component. The anchor is used to set MUI PopOver's position. When set to null it means there is no position i.e. it the popover becomes invisible. https://mui.com/material-ui/react-popover/ */
    restoreAnchor: () => void;
}

export const NotificationViewContext = createContext<INotificationViewContext>({
    notifications: [],
    setNotifications: (_: INotificationResult[]) => {},
    setRead: (_: INotificationResult[]) => {},
    deleteNotification: (_: INotificationResult) => {},
    deleteNotifications: () => {},
    restoreAnchor: () => {},
});
