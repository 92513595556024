import { useEffect, useState } from 'react';
import {
    AMultiselect,
    ARadioButtonGroupInputValue,
    AInputItem,
    AMultiselectValue,
    ACheckboxButtons,
    ACheckboxButtonsValue,
} from '../../../common/inputs';
import { SearchType } from '../../../interfaces/ISearchParameters';
import { IParameterExtension } from '../../shared/styles/types/subTypes';
import { GetValueToSave, GetValueForForm, SearchTypeFromIntToString } from '../../Helpers/Parsers';
import FormValidator from '../../../core/FormValidator';
import WeekInput from '../inputs/WeekInput';
import { ParameterValue } from '../../../core/resources';

export interface ParameterTypeToElementProps {
    type: SearchType;
    value: string[];
    onChange: (value: string[]) => void;
    validator: FormValidator;
    parameters: IParameterExtension[];
    personParameters: IParameterExtension[];
    announcementParameters: IParameterExtension[];
}

export const isMultiselect = (type: SearchType) => {
    switch (type) {
        case SearchType.County:
        case SearchType.Municipality:
        case SearchType.FeedSupplier:
        case SearchType.Dairy:
        case SearchType.PersonTitle:
        case SearchType.AnnouncementSalesType:
            return true;
        default:
            return false;
    }
};

export const isRadiobuton = (type: SearchType) => {
    switch (type) {
        case SearchType.ReturnExpiredOnes:
        case SearchType.Language:
        case SearchType.Species:
        case SearchType.FarmingTypeGeneric:
        case SearchType.PersonLanguage:
        case SearchType.PersonPhoneNumberType:
        case SearchType.AnnouncementType:
        case SearchType.AnnouncementsStatus:
            return true;
        default:
            return false;
    }
};

export default function ParameterTypeToElement(props: ParameterTypeToElementProps): JSX.Element {
    const { type, onChange, parameters, personParameters, announcementParameters } = props;
    const [valueToShow, setValueToShow] = useState<string[]>([]);

    useEffect(() => {
        setValueToShow(
            GetValueForForm(
                props.type,
                props.value,
                parameters,
                personParameters,
                announcementParameters
            )
        );
    }, [props.value]);

    const values = (type: SearchType) => {
        switch (type) {
            case SearchType.County:
                let countyRet: string[] = [];
                parameters
                    .find((r) => r.name === 'Regions')
                    ?.options.forEach((o) => countyRet.push(o.text));
                return countyRet;
            case SearchType.Municipality:
                let municipalityRet: string[] = [];
                parameters
                    .find((r) => r.name === 'Municipality')
                    ?.options.forEach((o) => municipalityRet.push(o.text));
                return municipalityRet;
            case SearchType.FeedSupplier:
                let feedSupplierRet: string[] = [];
                parameters
                    .find((r) => r.name === 'FeedSupplier')
                    ?.options.forEach((o) => feedSupplierRet.push(o.text));
                return feedSupplierRet;
            case SearchType.Dairy:
                let dairyRet: string[] = [];
                parameters
                    .find((r) => r.name === 'Dairy')
                    ?.options.forEach((o) => dairyRet.push(o.text));
                return dairyRet;
            case SearchType.ReturnExpiredOnes:
                let farmStatusRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                parameters
                    .find((r) => r.name === 'FarmStatus')
                    ?.options.forEach((o) => farmStatusRet.push({ text: o.text, value: o.code }));
                return farmStatusRet;
            case SearchType.Language:
                let languageRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                parameters
                    .find((r) => r.name === 'Language')
                    ?.options.forEach((o) => languageRet.push({ text: o.text, value: o.code }));
                return languageRet;
            case SearchType.Species:
                let speciesRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                parameters
                    .find((r) => r.name === 'Species')
                    ?.options.forEach((o) => speciesRet.push({ text: o.text, value: o.code }));
                return speciesRet;
            case SearchType.FarmingTypeGeneric:
                let farmingTypeBovineRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                parameters
                    .find((r) => r.name === 'FarmingTypeBovine')
                    ?.options.forEach((o) =>
                        farmingTypeBovineRet.push({ text: o.text, value: o.code })
                    );
                return farmingTypeBovineRet;

            case SearchType.PersonLanguage:
                let personLanguageRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                personParameters
                    .find((r) => r.name === 'Language')
                    ?.options.forEach((o) =>
                        personLanguageRet.push({ text: o.text, value: o.code })
                    );
                return personLanguageRet;
            case SearchType.PersonTitle:
                let personTitleRet: string[] = [];
                personParameters
                    .find((r) => r.name === 'Titles')
                    ?.options.forEach((o) => personTitleRet.push(o.text));
                return personTitleRet;
            case SearchType.PersonPhoneNumberType:
                let personPhoneNumberTypeRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                personParameters
                    .find((r) => r.name === 'PhoneNumberTypePerson')
                    ?.options.forEach((o) =>
                        personPhoneNumberTypeRet.push({ text: o.text, value: o.code })
                    );
                return personPhoneNumberTypeRet;

            case SearchType.AnnouncementsStatus:
                let announcementStatusRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                announcementParameters
                    .find((r) => r.name === 'Status')
                    ?.options.forEach((o) =>
                        announcementStatusRet.push({ text: o.text, value: o.code })
                    );
                return announcementStatusRet;
            case SearchType.AnnouncementType:
                let announcementTypeRet: AInputItem<ARadioButtonGroupInputValue>[] = [];
                announcementParameters
                    .find((r) => r.name === 'Type')
                    ?.options.forEach((o) =>
                        announcementTypeRet.push({ text: o.text, value: o.code })
                    );
                return announcementTypeRet;
            case SearchType.AnnouncementSalesType:
                let announcementSalesTypeRet: string[] = [];
                announcementParameters
                    .find((r) => r.name === 'SalesType')
                    ?.options.forEach((o) => announcementSalesTypeRet.push(o.text));
                return announcementSalesTypeRet;
            case SearchType.AnnouncementWeekOption:
                return announcementParameters.find((r) => r.name === 'Week')?.options ?? [];
            default:
                return [];
        }
    };

    if (isMultiselect(type))
        return (
            <AMultiselect
                id={
                    'multiselect-for-' +
                    SearchTypeFromIntToString(
                        type,
                        parameters,
                        personParameters,
                        announcementParameters
                    )
                }
                validator={props.validator}
                getOptionLabel={(opt) => opt as string}
                label={SearchTypeFromIntToString(
                    type,
                    parameters,
                    personParameters,
                    announcementParameters
                )}
                onChange={(r: AMultiselectValue[]) =>
                    onChange(
                        GetValueToSave(
                            props.type,
                            r as string[],
                            parameters,
                            personParameters,
                            announcementParameters
                        )
                    )
                }
                items={values(type)}
                value={valueToShow}
                withoutContainer
                required
            />
        );
    else if (isRadiobuton(type))
        return (
            <ACheckboxButtons
                id={
                    'radiobuttons-for-' +
                    SearchTypeFromIntToString(
                        type,
                        parameters,
                        personParameters,
                        announcementParameters
                    )
                }
                validator={props.validator}
                onChange={(e: ACheckboxButtonsValue[]) =>
                    onChange(
                        GetValueToSave(
                            props.type,
                            e as string[],
                            parameters,
                            personParameters,
                            announcementParameters
                        )
                    )
                }
                label={SearchTypeFromIntToString(
                    type,
                    parameters,
                    personParameters,
                    announcementParameters
                )}
                items={values(type) as AInputItem<ACheckboxButtonsValue>[]}
                value={valueToShow}
                withoutContainer
                required
            />
        );
    else if (type == SearchType.AnnouncementWeekOption) {
        return (
            <WeekInput
                id='announcement-week-search-parameter'
                onChange={onChange}
                options={values(type) as ParameterValue[]}
                selectedOption={valueToShow}
                validator={props.validator}
                label={SearchTypeFromIntToString(
                    type,
                    parameters,
                    personParameters,
                    announcementParameters
                )}
                withoutContainer
                required
            />
        );
    } else return <div />;
}
