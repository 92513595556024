import { ParameterValues } from '../../core/resources';
import { AnimalTypeEnum } from '../../interfaces/enums';
import { IProductionLine } from '../../interfaces/IBusinessEntities';
import { ISearchParameter, SearchType } from '../../interfaces/ISearchParameters';
import { IParameterExtension } from '../shared/styles/types/subTypes';
import { AnnouncementWeekIsBetweenTwoDatesSearchParameterCode } from './constants';

export function SearchTypeFromIntToString(
    type: number,
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[],
    announcementDictionary: IParameterExtension[]
) {
    switch (type) {
        case 1:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '3')?.text ?? ''
            );
        case 2:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '2')?.text ?? ''
            );
        case 3:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '0')?.text ?? ''
            );
        case 4:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '1')?.text ?? ''
            );
        case 5:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '5')?.text ?? ''
            );
        case 6:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '4')?.text ?? ''
            );
        case 7:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '6')?.text ?? ''
            );
        case 8:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '7')?.text ?? ''
            );
        case 9:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '8')?.text ?? ''
            );

        case 101:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '1')?.text ?? ''
            );
        case 102:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '2')?.text ?? ''
            );
        case 103:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '3')?.text ?? ''
            );

        case 200:
            return (
                announcementDictionary
                    .find((s) => s.name === 'AnnouncementSearchCriteria')
                    ?.options.find((o) => o.code === '0')?.text ?? ''
            );
        case 201:
            return (
                announcementDictionary
                    .find((s) => s.name === 'AnnouncementSearchCriteria')
                    ?.options.find((o) => o.code === '1')?.text ?? ''
            );
        case 202:
            return (
                announcementDictionary
                    .find((s) => s.name === 'AnnouncementSearchCriteria')
                    ?.options.find((o) => o.code === '2')?.text ?? ''
            );
        case 203:
            return (
                announcementDictionary
                    .find((s) => s.name === 'AnnouncementSearchCriteria')
                    ?.options.find((o) => o.code === '3')?.text ?? ''
            );
        default:
            return 'Unknown';
    }
}

export function GetProductionLinesFromIntList(
    values: IProductionLine[],
    farmingTypeBovineParams: ParameterValues,
    farmingTypePigParams: ParameterValues
) {
    let productionLineNames: string[] = [];

    values.forEach((line) => {
        if (line.Type === AnimalTypeEnum.Bovine) {
            let foundValues = farmingTypeBovineParams.filter(
                (p) => line.FarmingTypes.findIndex((f) => p.code === '0' + f) != -1
            );
            productionLineNames = productionLineNames.concat(foundValues.map((v) => v.text));
        } else {
            let foundValues = farmingTypePigParams.filter(
                (p) => line.FarmingTypes.findIndex((f) => p.code === '0' + f) != -1
            );
            productionLineNames = productionLineNames.concat(foundValues.map((v) => v.text));
        }
    });

    return productionLineNames;
}

export function GetSpeciesFronIntList(values: number[], species: ParameterValues) {
    return values.map((v) => species.find((o) => o.code === '0' + v)?.text ?? '');
}

export function GetValueToShow(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[],
    announcementDictionary: IParameterExtension[]
) {
    const countyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const municipalityFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const returnExpiredOnesFromBoolean = (values: string[]) => {
        return values.map((v) => {
            if (v === 'false')
                return (
                    dictionary
                        .find((r) => r.name === 'FarmStatus')
                        ?.options.find((o) => o.code === '0')?.text ?? ''
                );
            return (
                dictionary.find((r) => r.name === 'FarmStatus')?.options.find((o) => o.code === '1')
                    ?.text ?? ''
            );
        });
    };

    const languageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.text === v)
                    ?.text ?? ''
            );
        });
    };

    const speciesFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'Species')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const feedSupplierFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const dairyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'FarmingTypeBovine')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const personLanguageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.text === v)?.text ?? ''
            );
        });
    };

    const personTitleFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const personPhoneNumberTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'PhoneNumberTypePerson')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const announcementStatusFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Status')
                    ?.options.find((o) => o.text === v)?.text ?? ''
            );
        });
    };

    const announcementTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Type')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const announcementSalesTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'SalesType')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const announcementWeekFromValue = (values: string[]) => {
        let selectedOption = '';

        if (values.length !== 0) {
            selectedOption =
                announcementDictionary
                    .find((r) => r.name === 'Week')
                    ?.options.find((o) => parseInt(o.code).toString() === values[0])?.text ?? '';
        }

        if (
            values.length === 3 &&
            values[0] === AnnouncementWeekIsBetweenTwoDatesSearchParameterCode.toString()
        ) {
            return [[values[2], selectedOption, values[1]].join(' ')];
        } else if (values.length === 2) {
            return [[selectedOption, values[1]].join(' ')];
        }

        return [''];
    };

    switch (type) {
        case SearchType.County:
            return countyFromValue(values);
        case SearchType.Municipality:
            return municipalityFromValue(values);
        case SearchType.FeedSupplier:
            return feedSupplierFromValue(values);
        case SearchType.Dairy:
            return dairyFromValue(values);
        case SearchType.ReturnExpiredOnes:
            return returnExpiredOnesFromBoolean(values);
        case SearchType.Language:
            return languageFromValue(values);
        case SearchType.Species:
            return speciesFromValue(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return personLanguageFromValue(values);
        case SearchType.PersonTitle:
            return personTitleFromValue(values);
        case SearchType.PersonPhoneNumberType:
            return personPhoneNumberTypeFromValue(values);
        case SearchType.AnnouncementsStatus:
            return announcementStatusFromValue(values);
        case SearchType.AnnouncementType:
            return announcementTypeFromValue(values);
        case SearchType.AnnouncementSalesType:
            return announcementSalesTypeFromValue(values);
        case SearchType.AnnouncementWeekOption:
            return announcementWeekFromValue(values);
        default:
            return [];
    }
}

export function GetValueForForm(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[],
    announcementDictionary: IParameterExtension[]
) {
    const countyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const municipalityFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const returnExpiredOnesFromBoolean = (values: string[]) => {
        return values.map((v) => {
            if (v === 'false') return '0';
            return '1';
        });
    };

    const languageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.text === v)
                    ?.code ?? ''
            );
        });
    };

    const speciesFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'Species')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const feedSupplierFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const dairyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'FarmingTypeBovine')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const personLanguageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.text === v)?.code ?? ''
            );
        });
    };

    const personTitleFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const personPhoneNumberTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'PhoneNumberTypePerson')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const announcementStatusFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Status')
                    ?.options.find((o) => o.text === v)?.code ?? ''
            );
        });
    };

    const announcementTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Type')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const announcementSalesTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'SalesType')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const announcementWeekFromValue = (values: string[]) => {
        return values;
    };

    switch (type) {
        case SearchType.County:
            return countyFromValue(values);
        case SearchType.Municipality:
            return municipalityFromValue(values);
        case SearchType.FeedSupplier:
            return feedSupplierFromValue(values);
        case SearchType.Dairy:
            return dairyFromValue(values);
        case SearchType.ReturnExpiredOnes:
            return returnExpiredOnesFromBoolean(values);
        case SearchType.Language:
            return languageFromValue(values);
        case SearchType.Species:
            return speciesFromValue(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return personLanguageFromValue(values);
        case SearchType.PersonTitle:
            return personTitleFromValue(values);
        case SearchType.PersonPhoneNumberType:
            return personPhoneNumberTypeFromValue(values);
        case SearchType.AnnouncementsStatus:
            return announcementStatusFromValue(values);
        case SearchType.AnnouncementType:
            return announcementTypeFromValue(values);
        case SearchType.AnnouncementSalesType:
            return announcementSalesTypeFromValue(values);
        case SearchType.AnnouncementWeekOption:
            return announcementWeekFromValue(values);
        default:
            return [];
    }
}

export function GetValueToSave(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[],
    announcementDictionary: IParameterExtension[]
) {
    const valueFromCounty = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromMunicipality = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const booleanFromReturnExpiredOnes = (values: string[]) => {
        return values.map((v) => {
            if (v === '0') return 'false';
            return 'true';
        });
    };

    const valueFromLanguage = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.code === v)
                    ?.text ?? '0'
            );
        });
    };

    const valueFromSpecies = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    const valueFromFeedSupplier = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromDairy = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    const valueFromPersonLanguage = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.code === v)?.text ?? '0'
            );
        });
    };

    const valueFromPersonTitle = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromPersonPhoneNumberType = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    const valueFromAnnouncementStatus = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Status')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? '0'
            );
        });
    };

    const valueFromAnnouncementType = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'Type')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? '0'
            );
        });
    };

    const valueFromAnnouncementSalesType = (values: string[]) => {
        return values.map((v) => {
            return (
                announcementDictionary
                    .find((r) => r.name === 'SalesType')
                    ?.options.find((o) => o.text === v)?.code ?? '0'
            );
        });
    };

    const valueFromAnnouncementWeekOption = (values: string[]) => {
        return [values.length > 0 ? values[0] : '0'];
    };

    const valueFromAnnouncementWeekValue = (values: string[]) => {
        return [values.length > 1 ? values[1] : '0'];
    };

    const valueFromAnnouncementWeekOptionalValue = (values: string[]) => {
        return [values.length > 2 ? values[2] : '0'];
    };

    switch (type) {
        case SearchType.County:
            return valueFromCounty(values);
        case SearchType.Municipality:
            return valueFromMunicipality(values);
        case SearchType.FeedSupplier:
            return valueFromFeedSupplier(values);
        case SearchType.Dairy:
            return valueFromDairy(values);
        case SearchType.ReturnExpiredOnes:
            return booleanFromReturnExpiredOnes(values);
        case SearchType.Language:
            return valueFromLanguage(values);
        case SearchType.Species:
            return valueFromSpecies(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return valueFromPersonLanguage(values);
        case SearchType.PersonTitle:
            return valueFromPersonTitle(values);
        case SearchType.PersonPhoneNumberType:
            return valueFromPersonPhoneNumberType(values);
        case SearchType.AnnouncementsStatus:
            return valueFromAnnouncementStatus(values);
        case SearchType.AnnouncementType:
            return valueFromAnnouncementType(values);
        case SearchType.AnnouncementSalesType:
            return valueFromAnnouncementSalesType(values);
        case SearchType.AnnouncementWeekOption:
            return valueFromAnnouncementWeekOption(values);
        case SearchType.AnnouncementWeekValue:
            return valueFromAnnouncementWeekValue(values);
        case SearchType.AnnouncementWeekOptionalValue:
            return valueFromAnnouncementWeekOptionalValue(values);
        default:
            return [];
    }
}

export const GetAnnouncementWeekValue = (parameters: ISearchParameter[], opt: ISearchParameter) => {
    let val1 = '',
        val2 = '';
    let valIndex = parameters.findIndex((p) => p.Type === SearchType.AnnouncementWeekValue);

    if (valIndex !== -1 && parameters[valIndex].Value.length > 0)
        val1 = parameters[valIndex].Value[0];

    if (opt.Value[0] === AnnouncementWeekIsBetweenTwoDatesSearchParameterCode.toString()) {
        let optValIndex = parameters.findIndex(
            (p) => p.Type === SearchType.AnnouncementWeekOptionalValue
        );

        if (optValIndex !== -1 && parameters[optValIndex].Value.length > 0) {
            val2 = parameters[optValIndex].Value[0];

            return [opt.Value[0], val1, val2];
        }
    }

    return [opt.Value[0], val1];
};
